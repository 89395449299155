import { WalletDisconnectButton, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import donkLogo from "../../../../assets/img/donk.png";
import donkey from "../../../../assets/img/donkey.png";
import YourBalance from "../../../../components/YourBalance";
import { useAuthStore } from "../../../../store/auth";
export function Navbar() {
  const location = useLocation();
  const currentBalance = useAuthStore((state) => state.activeBalance);
  const isAuth = useAuthStore((state) => state.isAuth);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 999px)" });
  const [isDonked, setIsDonked] = useState(false);

  const isActive = (path) => location.pathname === path;
  const currency = Math.floor(currentBalance)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Define inline styles
  const styles = {
    nav: {
      backgroundColor: "#3E3E49",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "64px", // Adjust as needed
      padding: "0 16px", // Adjust as needed
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      zIndex: 50,
      position: "sticky",
      top: 0,
      width: "100%",
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
      gap: "16px", // Adjust gap between logo and buttons
    },
    button: {
      width: "100px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#F6FC9D",
      borderRadius: "4px",
      color: "#3E3E49",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      border: "none",
    },
    activeButton: {
      backgroundColor: "#f95050",
    },
    balanceText: {
      color: "white",
      marginRight: "8px",
      fontSize: 25,
    },
  };

  const menuStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      right: "20px",
      top: "16px", // Center vertically
    },
    bmBurgerBars: {
      background: "#ffffff",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmMenu: {
      background: "#3E3E49",
      overflow: "hidden", // Add this to prevent scrolling
    },
    bmMenuWrap: {
      position: "fixed",
      marginTop: "5px",
      height: "100%", // Full height
    },
    bmItemList: {
      color: "#F6FC9D",
      marginTop: "5px",
      alignItems: "center",
      display: "flex",
      flexDirection: "column", // Stack the links vertically
      fontSize: "2em",
    },
    bmItem: {
      display: "inline-block",
      textDecoration: "none",
      marginBottom: "10px", // Spacing between items
      transition: "color 0.2s",
      margin: "30",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)", // Overlay color
      top: "0px",
      left: "0px",
    },
  };

  // Add custom styles for the active menu item
  const activeMenuItemStyle = {
    color: "#f95050",
  };

  // Update getButtonStyle function
  const getMenuButtonStyle = (path) => {
    const isActive = location.pathname === path;
    return {
      ...menuStyles.bmItem,
      ...(isActive ? activeMenuItemStyle : {}),
    };
  };

  // Update your Navbar component render
  // ...

  // Apply active style if the path matches
  const getButtonStyle = (path) => ({
    ...styles.button,
    ...(isActive(path) ? styles.activeButton : {}),
  });

  const handleStateChange = (state) => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleDisconnect = () => {
    closeMenu();
  };

  if (isDonked) {
    return (<></>)
  }

  return (
    <nav className="w-full fixed top-5 z-20 right-0 lg:static  lg:py-5 max-w-screen-2xl mx-auto px-5 lg:px-10 flex gap-4 items-center">
      <NavLink to="/" className={"flex-shrink-0 w-14 lg:w-20"}>
        <img src={donkLogo} alt="Donk Logo" />
      </NavLink>
      {isMobile && (
        <>
          {isAuth && (
            <div className="lg:hidden w-full">
              <YourBalance balance={currency} />
            </div>
          )}

          <button onClick={handleStateChange} className="w-8 ml-auto flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 49" fill="none">
              <rect x="0.988281" width="61" height="11" rx="2" fill="#D9D9D9" />
              <rect x="0.988281" y="38" width="61" height="11" rx="2" fill="#D9D9D9" />
              <rect x="0.988281" y="19" width="61" height="11" rx="2" fill="#D9D9D9" />
            </svg>
          </button>
          <div onClick={closeMenu} className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="mobile-menu-inner">
              {isAuth ? <WalletDisconnectButton /> : <WalletMultiButton />}
              <NavLink to="/play" onClick={closeMenu} className="link">
                PLAY
              </NavLink>
              <NavLink to="/fund" onClick={closeMenu} className="link">
                FUND
              </NavLink>
              {/*
              <NavLink to="/withdraw" onClick={closeMenu} className="link">
                WITHDRAW
              </NavLink>
              */}
              <div className="w-full mt-auto">
                <img src={donkey} alt="Donk Logo" className="w-full h-auto" />
              </div>
            </div>
          </div>
        </>
      )}
      {!isMobile && (
        <div className="w-full flex items-center gap-3">
          <NavLink to="/play" className="link">
            PLAY
          </NavLink>
          <NavLink to="/fund" className="link">
            FUND
          </NavLink>
          {/*
          <NavLink to="/withdraw" className="link">
            WITHDRAW
          </NavLink>
          */}
          <div className="navbar ml-auto flex items-center gap-3 ">
            {isAuth ? (
              <>
                <div className="hidden lg:flex items-center gap-1">
                  <span style={styles.balanceText}>{`${currency} $DONK`}</span>
                  <img src={donkey} alt="Donk Logo" style={{ height: "48px" }} />
                </div>
                <WalletDisconnectButton />
              </>
            ) : (
              <>
                <img src={donkey} alt="Donk Logo" style={{ height: "48px" }} />
                <WalletMultiButton />
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
