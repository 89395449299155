// TODO: fix this for better usability
import classNames from "classnames";
import { Crown } from "phosphor-react";
import donkey from "../../../../assets/img/donkey.png";
import { useAuthStore } from "../../../../store/auth";

export function ScoreBoardPlayerItem({ player, position, onClick }) {
  const user = useAuthStore((state) => state.user);
  return (
    <button
      className={classNames(
        "group relative flex items-center bg-purple-100 even:bg-purple-200 text-black justify-between gap-4 rounded-md p-1 lg:px-5 hover:bg-secondary/80"
      )}
      key={player.id}>
      <div
        className={classNames(
          "flex max-w-full flex-1 items-center justify-between gap-1 lg:gap-4"
        )}>
        <span className=" max-w-[20ch] overflow-hidden truncate text-left group-hover:text-transparent lg:w-[50ch] lg:max-w-[50ch]">
          {player.id === user.id ? <strong>YOU (BEAST)</strong> : player.id}
        </span>
        <span className="absolute left-1/3 hidden animate-pulse text-text group-hover:block">
          WHO'S THIS DONKEY?
        </span>
        <strong
          className="text-right text-sm transition-colors group-hover:text-transparent lg:w-[20ch]  lg:max-w-[20ch] lg:text-lg"
          title={String(player.activeBalance)}>
          {Math.floor(player.activeBalance)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </strong>
      </div>
      <div className="relative pr-2">
        <img src={donkey} style={{ height: "30px" }} />
        {position <= 2 && (
          <Crown
            className={classNames("absolute right-0 bottom-0 transition-colors", {
              "text-yellow-400": position === 0,
              "text-gray-300": position === 1,
              "text-yellow-800": position === 2,
            })}
            weight="fill"
            size="20"
          />
        )}
      </div>
    </button>
  );
}
