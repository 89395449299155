import { useWallet } from "@solana/wallet-adapter-react";
import { collection, getDocs, getFirestore, limit, orderBy, query } from "firebase/firestore";
import { ArrowLeft, CircleDashed } from "phosphor-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import donkey from "../../assets/img/donkey.png";
import { Profile } from "../Profile";
import { ScoreBoardPlayerItem } from "./components/ScoreBoardPlayerItem";

export function ScoreBoardPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [scoreBoard, setScoreBoard] = useState([]);
  const [userProfile, setUserProfile] = useState(undefined);
  const { publicKey } = useWallet();
  const firestore = getFirestore();

  useEffect(() => {
    const getScoreBoardData = async () => {
      setIsLoading(true);

      // Reference to the 'Players' collection
      const playersCollection = collection(firestore, "Players");

      // Create a query to order players by 'activeBalance' in descending order and limit the results to 10
      const q = query(playersCollection, orderBy("activeBalance", "desc"), limit(10));

      try {
        const querySnapshot = await getDocs(q);
        const players = [];

        querySnapshot.forEach((doc) => {
          const playerData = doc.data();
          playerData.id = doc.id;
          players.push(playerData);
        });

        setScoreBoard(players);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching players:", error);
        setIsLoading(false);
      }
    };

    getScoreBoardData();
  }, []);

  return (
    <div className="flex w-full h-full flex-grow flex-col items-center justify-center gap-4 overflow-x-hidden px-5 lg:px-10">
      <div className="relative w-11/12 lg:w-3/5 mx-auto ">
        {
          isLoading ?
          <></>:
          <div>
            <img
              className="absolute top-0 left-1/2 lg:left-0 w-[40vw] h-auto z-0 -translate-x-[40%] -translate-y-[45%] lg:-translate-y-[15%] -rotate-90 -scale-x-100 lg:-rotate-0 lg:scale-x-100"
              src={donkey}
            />
            <img
              className="absolute bottom-0 right-0  w-[50vw] lg:w-[40vw] h-auto z-0 translate-x-[28%] lg:translate-x-[45%] translate-y-[20%] lg:translate-y-[25%] -rotate-90 -scale-x-100 lg:-rotate-0 lg:scale-x-100"
              src={donkey}
            />
          </div>
        }
        <div className="flex w-full  flex-col gap-3 rounded bg-purple-700 py-6 text-black px-5 lg:px-12 relative z-[1]">
          <div className="rounded-md bg-purple-100  text-center text-lg lg:text-4xl flex flex-col items-center gap-1 p-4">
            THE DONKIEST DONKEYS IN ALL THE LAND
            <span className="text-xs lg:text-lg leading-none">
              you should aspire to be like them
            </span>
          </div>
          {userProfile ? (
            <div className="mx-auto flex w-full  flex-col items-center justify-center">
              <Profile {...userProfile} />
              <button
                onClick={() => setUserProfile(undefined)}
                className="rounded-full bg-background p-2 text-text">
                <ArrowLeft weight="fill" />
              </button>
            </div>
          ) : (
            <div className="flex  flex-col gap-2 w-full ">
              {isLoading ? (
                <div className="flex w-full flex-col items-center justify-center py-4">
                  <CircleDashed className="animate-spin" size="40" weight="bold" />
                  <span className="mt-1 text-sm font-bold">
                    Loading Scores for people better than you...
                  </span>
                </div>
              ) : (
                <>
                  {scoreBoard.map((player, index) => {
                    return (
                      <ScoreBoardPlayerItem
                        key={player.id}
                        onClick={() =>
                          setUserProfile({
                            activeBalance: player.activeBalance,
                            id: player.id,
                            position: index,
                          })
                        }
                        position={index}
                        player={{
                          activeBalance: player.activeBalance,
                          id: player.id,
                          position: index,
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <Link to="/play" className="link">
        <span className="px-5"> LET'S play</span>
      </Link>
    </div>
  );
}
