import React from "react";

export default function Button({ label = "", children, ...props }) {
  return (
    <button
      className="bg-purple-200 hover:bg-purple-300 text-black border border-black rounded text-xs  lg:text-lg p-1 lg:px-4 uppercase"
      {...props}>
      {children ?? label}
    </button>
  );
}
