import { DefaultLayout } from '../layouts/DefaultLayout';
import { PlinkoGamePage } from '../pages/Games/Plinko';
import { LoginPage } from '../pages/Login';
import { ScoreBoardPage } from '../pages/ScoreBoard';
import { Fund } from '../pages/Fund';
import { Withdraw } from '../pages/Withdraw';
import { Donked } from '../pages/Donked';
import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';

import { NotFound } from './components/NotFound';
import { RequireAuth } from './components/RequireAuth';

export function Routes() {
  // return (
  //   <BrowserRouter>
  //     <Switch>
  //       <Route element={<DefaultLayout />}>
  //         <Route path="*" element={<Donked />} />
  //       </Route>
  //     </Switch>
  //   </BrowserRouter>
  // )
  return (
    <BrowserRouter>
      <Switch>
        <Route element={<DefaultLayout />}>
          <Route element={<RequireAuth />}>
            <Route path="/play" element={<PlinkoGamePage />} />
          </Route>
          <Route path="/" element={<ScoreBoardPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/fund" element={<Fund />} />
          {/* <Route path="/withdraw" element={<Withdraw />} /> */}
          <Route path="/donked" element={<Donked /> } />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
