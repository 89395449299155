import React, { useEffect } from 'react';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useAuthStore } from '../../store/auth'; // Make sure to import the correct path to your useAuthStore

function AuthenticatedUserChecker() {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const authenticateUser = useAuthStore(state => state.authenticateUser);
  const signOut = useAuthStore(state => state.signOut);
  let currentPublicKey = '';

  useEffect(() => {
    if (publicKey) {
      authenticateUser(publicKey);
    }
    else {
      signOut()
    }
  }, [publicKey]);
}

export default AuthenticatedUserChecker;
