// multiplierImages
import multiplier0ImgD from '../../../../../../assets/img/multipliers/multiplier0D.png'
import multiplier0ImgM from '../../../../../../assets/img/multipliers/multiplier0M.png'

import multiplier0dot1ImgD from '../../../../../../assets/img/multipliers/multiplier0.1D.png'
import multiplier0dot1ImgM from '../../../../../../assets/img/multipliers/multiplier0.1M.png'

import multiplier0dot25ImgD from '../../../../../../assets/img/multipliers/multiplier0.25D.png'
import multiplier0dot25ImgM from '../../../../../../assets/img/multipliers/multiplier0.25M.png'

import multiplier1dot5ImgD from '../../../../../../assets/img/multipliers/multiplier1.5D.png'
import multiplier1dot5ImgM from '../../../../../../assets/img/multipliers/multiplier1.5M.png'

import multiplier2ImgD from '../../../../../../assets/img/multipliers/multiplier2D.png'
import multiplier2ImgM from '../../../../../../assets/img/multipliers/multiplier2M.png'

import multiplier5ImgD from '../../../../../../assets/img/multipliers/multiplier5D.png'
import multiplier5ImgM from '../../../../../../assets/img/multipliers/multiplier5M.png'

import multiplier40ImgD from '../../../../../../assets/img/multipliers/multiplier40D.png'
import multiplier40ImgM from '../../../../../../assets/img/multipliers/multiplier40M.png'

import multiplier110ImgD from '../../../../../../assets/img/multipliers/multiplier110D.png'
import multiplier110ImgM from '../../../../../../assets/img/multipliers/multiplier110M.png'
// multiplierSoundEffects
import multiplierBest from '../../../../../../assets/sounds/multiplier-best.wav'
import multiplierGood from '../../../../../../assets/sounds/multiplier-good.wav'
import multiplierLow from '../../../../../../assets/sounds/multiplier-low.wav'
import multiplierRegular from '../../../../../../assets/sounds/multiplier-regular.wav'

const multiplierSounds = {
  110: multiplierBest,
  40: multiplierBest,
  5: multiplierGood,
  2: multiplierGood,
  1.5: multiplierGood,
  0.25: multiplierLow,
  0.1: multiplierLow,
  0: multiplierLow
};

const multipliersD = {
  110: {
    label: 'block-110',
    sound: multiplierBest,
    img: multiplier110ImgD
  },
  40: {
    label: 'block-40',
    sound: multiplierBest,
    img: multiplier40ImgD
  },
  5: {
    label: 'block-5',
    sound: multiplierGood,
    img: multiplier5ImgD
  },
  2: {
    label: 'block-2',
    sound: multiplierGood,
    img: multiplier2ImgD
  },
  1.5: {
    label: 'block-1.5',
    sound: multiplierRegular,
    img: multiplier1dot5ImgD
  },
  0.25: {
    label: 'block-.25',
    sound: multiplierRegular,
    img: multiplier0dot25ImgD
  },
  0.3: {
    label: 'block-.3',
    sound: multiplierRegular,
    img: multiplier0dot25ImgD
  },
  0.1: {
    label: 'block-.1',
    sound: multiplierLow,
    img: multiplier0dot1ImgD
  },
  0: {
    label: 'block-0',
    sound: multiplierLow,
    img: multiplier0ImgD
  },
}

const multipliersM = {
  110: {
    label: 'block-110',
    sound: multiplierBest,
    img: multiplier110ImgM
  },
  40: {
    label: 'block-40',
    sound: multiplierBest,
    img: multiplier40ImgM
  },
  5: {
    label: 'block-5',
    sound: multiplierGood,
    img: multiplier5ImgM
  },
  2: {
    label: 'block-2',
    sound: multiplierGood,
    img: multiplier2ImgM
  },
  1.5: {
    label: 'block-1.5',
    sound: multiplierRegular,
    img: multiplier1dot5ImgM
  },
  0.25: {
    label: 'block-0.25',
    sound: multiplierRegular,
    img: multiplier0dot25ImgM
  },
  0.1: {
    label: 'block-0.1',
    sound: multiplierLow,
    img: multiplier0dot1ImgM
  },
  0: {
    label: 'block-0',
    sound: multiplierLow,
    img: multiplier0ImgM
  },
}

export const multiplyDesktop = [
  multipliersD[40],
  multipliersD[0.3],
  multipliersD[2],
  multipliersD[0.1],
  multipliersD[5],
  multipliersD[0],
  multipliersD[1.5],
  multipliersD[0.25],
  multipliersD[110],
  multipliersD[0.25],
  multipliersD[1.5],
  multipliersD[0],
  multipliersD[5],
  multipliersD[.1],
  multipliersD[2],
  multipliersD[0.3],
  multipliersD[40],
]

export const multiplyMobile = [
  multipliersM[40],
  multipliersM[0.25],
  multipliersM[2],
  multipliersM[0.1],
  multipliersM[5],
  multipliersM[0],
  multipliersM[1.5],
  multipliersM[0.25],
  multipliersM[110],
  multipliersM[0.25],
  multipliersM[1.5],
  multipliersM[0],
  multipliersM[5],
  multipliersM[.1],
  multipliersM[2],
  multipliersM[0.25],
  multipliersM[40],
]

export const multiplierSizes = {
  desktop: multiplyDesktop,
  mobile: multiplyMobile
}

export function getMultiplierByLinesQnt(size) {
  return multiplierSizes[size];
}

export function getMultiplierSound(value) {
  return multiplierSounds[value];
}
