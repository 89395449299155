import './styles/global.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { Routes } from './routes'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import AuthenticatedUserChecker from './routes/components/AuthenticatedUserChecker'

require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  const network = "mainnet-beta";
  const endpoint = "https://neat-compatible-river.solana-mainnet.quiknode.pro/a89f7697355939c1ee2450b232eb502981b755d1/";
  const wallets = React.useMemo(
    () => [],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <>
            <Routes />
            <Toaster />
            <AuthenticatedUserChecker />
          </>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
