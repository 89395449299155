import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { produce } from 'immer';
import { auth, database } from '../lib/firebase';
import toast from 'react-hot-toast';
import { create } from 'zustand';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';

const userInitialState = {
  id: '',
  activeBalance: 0,
  amountDeposited: 0,
  amountWithdrawn: 0,
  amountEarned: 0,
};

// TODO: need to readd the database init - make this auth read only if possible
const firestore = getFirestore();

export const useAuthStore = create((setState, getState) => ({
  user: userInitialState,
  isAuthLoading: false,
  isBetLoading: false,
  isAuth: false,
  activeBalance: 0,

  toggleBetLoading: (bool) => {
    setState(state => ({
      ...state,
      isBetLoading: bool,
    }))
  },
  incrementBalance: async (amount) => {
    try {
      const newActiveBalance = getState().activeBalance + amount
      setState((state) => ({
        ...state,
        activeBalance: newActiveBalance,
      }));
    } catch (error) {
      toast.error('There was an error incrementing your active balance, though your bet has gone through! Refresh to see the results!')
      console.error('decrementBalanceError', error)
    }
  },
  decrementBalance: async (amount) => {
    try {
      const newActiveBalance = getState().activeBalance - amount
      setState((state) => ({
        ...state,
        activeBalance: newActiveBalance,
      }));
    } catch (error) {
      toast.error('There was an error decrementing your active balance, though your bet has gone through! Refresh to see the results!')
      console.error('decrementBalanceError', error)
    }
  },
  authenticateUser: async (publicKey) => {
    try {
      setState(state => ({ ...state, isAuthLoading: true }));
      const userDocRef = doc(firestore, 'Players', publicKey.toString());

      const userDocSnapshot = await getDoc(userDocRef);

      let user = {};

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        user = {
          id: userDocSnapshot.id,
          activeBalance: userData.activeBalance || 0,
          solDeposited: userData.solDeposited || 0,
          donkDeposited: userData.donkDeposited || 0,
          amountWithdrawn: userData.amountWithdrawn || 0,
          amountEarned: userData.amountEarned || 0,
        }
      }
      else {
        const newData = {
          activeBalance: 0,
          solDeposited: 0,
          donkDeposited: 0,
          amountWithdrawn: 0,
          amountEarned: 0,
        };
        await setDoc(userDocRef, newData)
        user = {
          ...newData,
          id: publicKey.toString(),
        }
      }
      setState(state => ({
        ...state,
        isAuth: true,
        isAuthLoading: false,
        user: user,
        activeBalance: user.activeBalance,
      }));
    } catch (error) {
      toast.error('There was an error when trying to login, please try again.');
      console.error('signInError', error);
    }
  },
  signOut: async () => {
    try {
      setState(state => ({
        ...state,
        user: userInitialState,
        isAuth: false,
        activeBalance: 0,
      }));
    } catch (error) {
      toast.error('There was an issue logging out, please reresh and try again.');
      console.error('signOutError', error);
    }
  },
  // TODO: need to see if we still need this - i doubt it
  // TODO: research what produce does
  setUser: (user) => {
    try {
      setState(
        produce(state => {
          state.user = user;
          state.isAuth = true;
          state.isAuthLoading = false;
        })
      );
    } catch (error) {
      toast.error('There was an issue trying to authenticate the user');
      console.error('setUserError', error);
    }
  }
}));
