import React from "react";

export default function YourBalance({ balance = 0 }) {
  return (
    <div className="bg-purple-100 rounded border-black border flex w-full justify-between items-center uppercase p-1 px-4 lg:text-xl text-black">
      <p>Current Balance</p>
      <p>{balance}</p>
    </div>
  );
}
