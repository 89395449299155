import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { WalletSignTransactionError } from "@solana/wallet-adapter-base";
import { useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import InputValue from "../../components/InputValue";
import YourBalance from "../../components/YourBalance";
import { useAuthStore } from "../../store/auth";

const solFundingOptions = {
  0.1: { donkAmount: "10M $DONK", donkValue: 10000000 },
  0.5: { donkAmount: "75M $DONK", donkValue: 75000000 },
  1: { donkAmount: "175M $DONK", donkValue: 175000000 },
  5: {donkAmount: "5B $DONK", donkValue: 5000000000}
};

export function Fund() {
  const { publicKey, sendTransaction, signTransaction } = useWallet();
  const [fundWithSOL, setFundWithSOL] = useState(true);
  const [inputAmount, setInputAmount] = useState("");
  const [walletFunds, setWalletFunds] = useState(0);

  const [toggleDonkBalance, setToggleDonkBalance] = useState(false);

  const isAuth = useAuthStore((state) => state.isAuth);
  const currentBalance = useAuthStore((state) => state.activeBalance);
  const decrementBalance = useAuthStore((state) => state.decrementBalance);
  const incrementBalance = useAuthStore((state) => state.incrementBalance);

  useEffect(() => {
    async function findTokenBalance() {
      if (!publicKey) {
        // If publicKey is not available, exit the function
        return;
      }
      try {
        const connection = new web3.Connection(process.env.QUICKNODE);
        const filter = {
          programId: new web3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"),
          mint: new web3.PublicKey("APH5hRmL46eSQkTKZQ6q2Pk4AaSR4Uuwu7DBCxSder9k"),
        };

        const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, filter);

        if (tokenAccounts.value.length > 0) {
          const accountInfo = tokenAccounts.value[0].account.data.parsed.info;
          setWalletFunds(accountInfo.tokenAmount.uiAmount);
        } else {
          setWalletFunds(0);
        }
      } catch (error) {
        console.error("Error finding token balance:", error);
        // Optionally handle the error by updating the state or showing a message
      }
    }

    findTokenBalance();
  }, [publicKey, toggleDonkBalance]);

  const currency = Math.floor(currentBalance)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const handleFundOptionToggle = (option) => {
    setFundWithSOL(option === "SOL");
  };

  const handleAmountChange = (e) => {
    setInputAmount(e.target.value);
  };

  const handleMax = () => {
    setInputAmount(Math.floor(walletFunds));
  };

  const handleHalf = () => {
    setInputAmount(Math.floor(walletFunds / 2));
  };

  const fundWithSol = async (solAmountTemp) => {
    const solAmount = Number(solAmountTemp);
    try {
      if (!publicKey) {
        toast.error("You need to connect your wallet first ya donkey!");
        return;
      }
      const firestore = getFirestore();
      const solanaConnection = new web3.Connection(process.env.QUICKNODE);

      const pendingTransactions = await getPendingTransactions(firestore, solanaConnection);
      if (pendingTransactions)
        throw Error(
          "We found some discrepencies in the database with your active balance. Please try again"
        );
      const userDocRef = doc(firestore, "Players", publicKey.toString());
      const uid = crypto.randomUUID();
      const pendingDepositRef = doc(firestore, "Deposits", uid);

      try {
        await runTransaction(firestore, async (firebaseTransaction) => {
          const userDocSnapshot = await firebaseTransaction.get(userDocRef);
          await setDoc(pendingDepositRef, {
            player: userDocRef,
            amount: solAmount,
            date: Date.now(),
            state: "pending",
            currency: "SOL",
          });
        });

        const donkWallet = await new web3.PublicKey("28eAvc3M7Eyfx87bPftA6iq7FafoCvNwD9FKGxPXqaWH");
        const donkFeeWallet = await new web3.PublicKey(
          "5RHM4YkD54i2tXKfEbkTXKmrZ4MutiYc9y1LBZW7zzg1"
        );
        const donkToken = await new web3.PublicKey("APH5hRmL46eSQkTKZQ6q2Pk4AaSR4Uuwu7DBCxSder9k");
        // const totalSol = solAmount + 0.025;
        const totalSol = solAmount;

        var transaction = new web3.Transaction().add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: donkFeeWallet,
            lamports: web3.LAMPORTS_PER_SOL * totalSol,
          })
        );

        const latestBlockhash = await solanaConnection.getLatestBlockhash();
        transaction.feePayer = publicKey;
        transaction.recentBlockhash = latestBlockhash.blockhash;

        try {
          const sendSigned = await signTransaction(transaction);
          const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize());
          const toastID = toast.loading("Sending transaction... Trying to verify its success");
          await runTransaction(firestore, async (firebaseTransaction) => {
            await firebaseTransaction.update(pendingDepositRef, { signature: signature });
          });
          try {
            const confirmationResult = await solanaConnection.confirmTransaction({
              blockhash: latestBlockhash.blockhash,
              lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
              signature: signature,
            });
            if (confirmationResult?.value?.err === null) {
              await runTransaction(firestore, async (firebaseTransaction) => {
                const userDocSnapshot = await firebaseTransaction.get(userDocRef);
                const currentActiveBalance = userDocSnapshot.data().activeBalance;

                let newBalance =
                  currentActiveBalance + Math.floor(solFundingOptions[solAmount].donkValue);
                let solDeposited = userDocSnapshot.data().solDeposited + solAmount;
                await firebaseTransaction.update(userDocRef, {
                  activeBalance: newBalance,
                  solDeposited: solDeposited,
                });
                await firebaseTransaction.update(pendingDepositRef, { state: "confirmed" });
              });
              toast.success("Your deposit was successful!", {
                id: toastID,
              });
              incrementBalance(Math.floor(solAmount * solFundingOptions[solAmount].donkValue));
            } else {
              toast.error("Was unable to confirm the transaction - still pending.");
            }
          } catch (error) {
            // An error occurred (e.g., network issue, invalid signature)
            console.error("Error confirming transaction:", error);
            toast.error("Error in transaction confirmation. Please try again later.");
          }
        } catch (error) {
          console.log(error);
          throw error;
        }
      } catch (error) {
        if (error instanceof WalletSignTransactionError) {
          toast.error("You rejected the transaction.");
          return;
        } else {
          console.log(error);
          toast.error(error.message);
          return;
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    // start her
  };

  const fundWithDonk = async () => {
    const donkAmount = Number(inputAmount);
    try {
      if (!publicKey) {
        toast.error("You need to connect your wallet first ya donkey!");
        return;
      }
      const firestore = getFirestore();
      const solanaConnection = new web3.Connection(process.env.QUICKNODE);

      const pendingTransactions = await getPendingTransactions(firestore, solanaConnection);
      if (pendingTransactions)
        throw Error(
          "We found some discrepencies in the database with your active balance. Please try again"
        );
      const userDocRef = doc(firestore, "Players", publicKey.toString());
      const uid = crypto.randomUUID();
      const pendingDepositRef = doc(firestore, "Deposits", uid);

      try {
        await runTransaction(firestore, async (firebaseTransaction) => {
          const userDocSnapshot = await firebaseTransaction.get(userDocRef);
          await setDoc(pendingDepositRef, {
            player: userDocRef,
            amount: donkAmount,
            date: Date.now(),
            state: "pending",
            currency: "DONK",
          });
        });

        const donkWallet = await new web3.PublicKey("28eAvc3M7Eyfx87bPftA6iq7FafoCvNwD9FKGxPXqaWH");
        const donkFeeWallet = await new web3.PublicKey(
          "5RHM4YkD54i2tXKfEbkTXKmrZ4MutiYc9y1LBZW7zzg1"
        );
        const donkToken = await new web3.PublicKey("APH5hRmL46eSQkTKZQ6q2Pk4AaSR4Uuwu7DBCxSder9k");

        var transaction = new web3.Transaction().add(
          web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: donkFeeWallet,
            lamports: web3.LAMPORTS_PER_SOL * 0.025,
          })
        );

        const donkWalletAccount = await getAssociatedTokenAddress(donkToken, donkWallet);

        // these shouldnt get hit - its merely a precaution...
        try {
          let donkAccount = await getAccount(solanaConnection, donkWalletAccount);
        } catch (error) {
          toast.error("Donk wallet is experiencing an error - retrying...");
          try {
            let transactionTemp = new web3.Transaction().add(
              createAssociatedTokenAccountInstruction(
                publicKey,
                donkWalletAccount,
                donkWallet,
                donkToken,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID
              )
            );
            let signatureTemp = await sendTransaction(transactionTemp, solanaConnection);
            await solanaConnection.confirmTransaction(signatureTemp, "processed");
          } catch (error) {
            throw error;
          }
        }

        const userDonkAccount = await getAssociatedTokenAddress(donkToken, publicKey);

        try {
          let userDonkAccountTemp = await getAccount(solanaConnection, userDonkAccount);
        } catch (error) {
          toast.error("Your donk account doesnt exist yet you donkey... Create it now");
          try {
            let transactionTemp = new web3.Transaction().add(
              createAssociatedTokenAccountInstruction(
                publicKey,
                userDonkAccount,
                publicKey,
                donkToken,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID
              )
            );
            let signatureTemp = await sendTransaction(transactionTemp, solanaConnection);
            await solanaConnection.confirmTransaction(signatureTemp, "processed");
          } catch (error) {
            toast.error("There was an issue creating your donk account");
            throw error;
          }
        }

        let tokenInfo = await solanaConnection.getTokenSupply(donkToken);
        let decimal = tokenInfo.value.decimals;
        let finalDecimal = 10 ** decimal;

        transaction.add(
          createTransferInstruction(
            userDonkAccount,
            donkWalletAccount,
            publicKey,
            Math.floor(donkAmount) * finalDecimal,
            [],
            TOKEN_PROGRAM_ID
          )
        );

        const latestBlockhash = await solanaConnection.getLatestBlockhash();
        transaction.feePayer = publicKey;
        transaction.recentBlockhash = latestBlockhash.blockhash;

        try {
          const sendSigned = await signTransaction(transaction);
          const toastID = toast.loading("Sending transaction... Trying to verify its success");
          const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize());
          // const signature = await solanaConnection.sendAndConfirmTransaction(signedTransaction, [publicKey]);
          await runTransaction(firestore, async (firebaseTransaction) => {
            await firebaseTransaction.update(pendingDepositRef, { signature: signature });
          });
          try {
            const confirmationResult = await solanaConnection.confirmTransaction({
              blockhash: latestBlockhash.blockhash,
              lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
              signature: signature,
            });
            if (confirmationResult?.value?.err === null) {
              await runTransaction(firestore, async (firebaseTransaction) => {
                const userDocSnapshot = await firebaseTransaction.get(userDocRef);
                const currentActiveBalance = userDocSnapshot.data().activeBalance;

                let newBalance = currentActiveBalance + Math.floor(donkAmount);
                let donkDeposited = userDocSnapshot.data().donkDeposited + Math.floor(donkAmount);
                await firebaseTransaction.update(userDocRef, {
                  activeBalance: newBalance,
                  donkDeposited: donkDeposited,
                });
                await firebaseTransaction.update(pendingDepositRef, { state: "confirmed" });
              });
              setToggleDonkBalance(!toggleDonkBalance);
              toast.success("Your deposit was successful!", {
                id: toastID,
              });
              incrementBalance(Math.floor(donkAmount));
            } else {
              toast.error("Was unable to confirm the transaction - still pending.", {
                id: toastID,
              });
            }
          } catch (error) {
            // An error occurred (e.g., network issue, invalid signature)
            console.error("Error confirming transaction:", error);
            toast.error("Error in transaction confirmation. Please try again later.");
          }
        } catch (error) {
          console.log(error);
          throw error;
        }
      } catch (error) {
        if (error instanceof WalletSignTransactionError) {
          toast.error("You rejected the transaction.");
          return;
        } else {
          console.log(error);
          toast.error(error.message);
          return;
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    // start her
  };

  async function getPendingTransactions(firestore, solanaConnection) {
    let playerRef = doc(firestore, "Players", publicKey.toString());
    const userDocSnapshot = await getDoc(playerRef);
    if (!userDocSnapshot.exists()) {
      toast.error("this user doesnt exist in the database");
      return true;
    }

    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const pendingWithdrawalsQuery = query(
      withdrawalsCollectionRef,
      where("player", "==", playerRef),
      where("state", "==", "pending"),
      where("signature", "!=", null)
    );

    const depositsCollectionRef = collection(firestore, "Deposits");
    const pendingDepositsQuery = query(
      depositsCollectionRef,
      where("player", "==", playerRef),
      where("state", "==", "pending"),
      where("signature", "!=", null)
    );
    try {
      const pendingWithdrawals = getPendingWithdrawals(
        pendingWithdrawalsQuery,
        userDocSnapshot,
        solanaConnection,
        firestore,
        playerRef
      );
      const pendingDeposits = getPendingDeposits(
        pendingDepositsQuery,
        userDocSnapshot,
        solanaConnection,
        firestore,
        playerRef
      );
      return pendingWithdrawals || pendingDeposits;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      return true;
    }
  }

  async function getPendingWithdrawals( pendingWithdrawalsQuery, userDocSnapshot, solanaConnection, firestore, playerRef) {
    try {
      const querySnapshot = await getDocs(pendingWithdrawalsQuery);
      if (querySnapshot.empty) {
        return false;
      }
      for (const docSnapshot of querySnapshot.docs) {
        const withdrawalData = docSnapshot.data();
        const signature = withdrawalData.signature;

        let latestBlockhashTemp = await solanaConnection.getLatestBlockhash();

        try {
          // check pending
          const { value: statuses } = await solanaConnection.getSignatureStatuses([signature]);
          const status = statuses && statuses[0];
          if (status && status.confirmationStatus === "processed") {
            toast.error(
              "You have a transaction pending. Please wait until it is confirmed before continuing. Try again in a bit."
            );
            return true;
          }
          // transaction is not pending - either failed or succeeded
          const confirmedTransaction = await solanaConnection.getConfirmedTransaction(
            signature,
            "finalized"
          );

          if (
            confirmedTransaction &&
            confirmedTransaction.meta &&
            confirmedTransaction.meta.err === null
          ) {
            // The transaction has been confirmed
            await runTransaction(firestore, async (firebaseTransaction) => {
              const newBalance = userDocSnapshot.data().activeBalance - withdrawalData.amount;
              const amountWithdrawn =
                userDocSnapshot.data().amountWithdrawn + withdrawalData.amount;
              firebaseTransaction.update(playerRef, {
                activeBalance: newBalance,
                amountWithdrawn: amountWithdrawn,
              });
              firebaseTransaction.update(docSnapshot.ref, { state: "confirmed" });
            });
            decrementBalance(withdrawalData.amount);
            toast.success("We just updated your account. Please try again.");
            return true;
          } else if (
            confirmedTransaction &&
            confirmedTransaction.meta &&
            confirmedTransaction.meta.err !== null
          ) {
            // The transaction has failed
            await runTransaction(firestore, async (firebaseTransaction) => {
              firebaseTransaction.update(docSnapshot.ref, { state: "failed" });
            });
            toast.error(
              "There was a pending failed transaction. You account has been updated, please try again."
            );
            return true;
          }
          return true;
        } catch (error) {
          // Check if the error message indicates a network error
          if (error.message.includes("Failed to fetch")) {
            toast.error("Network error: Unable to connect to Solana RPC.");
          } else {
            toast.error(
              "An unexpected error occurred related to pending transactions on your account. Please try again later."
            );
          }
          return true;
        }
      }
      return false;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      return true;
    }
  }

  async function getPendingDeposits( pendingDepositsQuery, userDocSnapshot, solanaConnection, firestore, playerRef ) {
    try {
      const querySnapshot = await getDocs(pendingDepositsQuery);
      if (querySnapshot.empty) {
        return false;
      }
      for (const docSnapshot of querySnapshot.docs) {
        const depositData = docSnapshot.data();
        const signature = depositData.signature;

        let latestBlockhashTemp = await solanaConnection.getLatestBlockhash();

        try {
          // check pending
          const { value: statuses } = await solanaConnection.getSignatureStatuses([signature]);
          const status = statuses && statuses[0];
          if (status && status.confirmationStatus === "processed") {
            toast.error(
              "You have a transaction pending. Please wait until it is confirmed before continuing. Try again in a bit."
            );
            return true;
          }
          // transaction is not pending - either failed or succeeded
          const confirmedTransaction = await solanaConnection.getConfirmedTransaction(
            signature,
            "finalized"
          );

          if (
            confirmedTransaction &&
            confirmedTransaction.meta &&
            confirmedTransaction.meta.err === null
          ) {
            // The transaction has been confirmed
            await runTransaction(firestore, async (firebaseTransaction) => {
              if (depositData.currency === "SOL") {
                const donkValue = solFundingOptions[depositData.amount].donkValue;
                const newBalance = userDocSnapshot.data().activeBalance + donkValue;
                const solDeposited = userDocSnapshot.data().solDeposited + depositData.amount;
                firebaseTransaction.update(playerRef, {
                  activeBalance: newBalance,
                  solDeposited: solDeposited,
                });
              } else {
                const newBalance = userDocSnapshot.data().activeBalance + depositData.amount;
                const donkDeposited = userDocSnapshot.data().donkDeposited + depositData.amount;
                firebaseTransaction.update(playerRef, {
                  activeBalance: newBalance,
                  donkDeposited: donkDeposited,
                });
              }
              firebaseTransaction.update(docSnapshot.ref, { state: "confirmed" });
            });
            depositData.currency === "SOL"
              ? incrementBalance(solFundingOptions[depositData.amount].donkValue)
              : incrementBalance(depositData.amount);
            toast.success("We just updated your account. Please try again.");
            return true;
          } else if (
            confirmedTransaction &&
            confirmedTransaction.meta &&
            confirmedTransaction.meta.err !== null
          ) {
            // The transaction has failed
            await runTransaction(firestore, async (firebaseTransaction) => {
              firebaseTransaction.update(docSnapshot.ref, { state: "failed" });
            });
            toast.error(
              "There was a pending failed transaction. You account has been updated, please try again."
            );
            return true;
          }
          return true;
        } catch (error) {
          // Check if the error message indicates a network error
          if (error.message.includes("Failed to fetch")) {
            toast.error("Network error: Unable to connect to Solana RPC.");
          } else {
            toast.error(
              "An unexpected error occurred related to pending transactions on your account. Please try again later."
            );
          }
          return true;
        }
      }
      return false;
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      return true;
    }
  }

  const getFundButtonStyle = (isDisabled) => ({
    ...styles.fundButton,
    backgroundColor: isDisabled ? "#6c757d" : "#4C6EF5", // Grey color when disabled
  });

  return (
    <div className="w-full lg:w-1/2 xl:w-1/2 max-w-2xl px-5 lg:px-10 mx-auto lg:py-10 flex flex-col gap-5 xl:gap-8 pt-10 relative">
      <div className="opacity-0 pointer-events-none lg:opacity-100 relative z-10">
        <YourBalance balance={currency} />
      </div>

      <div className="rounded-md bg-purple-700 p-8 flex flex-col gap-4 items-center text-white relative z-10">
        <div className="w-full xl:w-2/3 gap-2 lg:gap-8 grid grid-cols-2">
          <button
            className={`link text-black ${fundWithSOL ? "active" : ""}`}
            onClick={() => handleFundOptionToggle("SOL")}>
            SOL
          </button>
          <button
            style={{backgroundColor: "#ACACAC"}}
            className={`link text-black ${!fundWithSOL ? "active" : ""}`}
            disabled={true}
            onClick={() => handleFundOptionToggle("DONK")}>
            $DONK
          </button>
        </div>

        {fundWithSOL ? (
          <>
            <div className="grid lg:grid-cols-4 gap-3 lg:w-max w-2/3">
              {Object.keys(solFundingOptions)
                .sort((a, b) => parseFloat(a) - parseFloat(b))
                .map((key, index) => (
                <div className="w-full">
                  <button
                    disabled={!publicKey}
                    onClick={() => {
                      fundWithSol(key);
                    }}
                    key={index}
                    className="bg-white text-purple-700 border border-black rounded p-1 px-4 uppercase w-full">
                    <div>{key} SOL</div>
                  </button>
                  <div className="text-white uppercase text-center">
                    {solFundingOptions[key].donkAmount}
                  </div>
                </div>
              ))}
            </div>
            <p className="text-center">Your account will be credited with the $DONK immediately</p>
          </>
        ) : (
          <>
            <span className="uppercase text-lg lg:text-3xl">DONK in wallet</span>
            <span className="uppercase text-lg lg:text-3xl">{walletFunds}</span>
            <div className="flex items-center gap-2">
              <span className="xl:text-2xl">Add</span>
              <InputValue
                type="text"
                value={inputAmount}
                onChange={handleAmountChange}
                placeholder="$DONK"
              />
              <Button onClick={handleMax}>Max</Button>
              <Button onClick={handleHalf}>Half</Button>
            </div>
            <button
              className="bg-white hover:bg-purple-100 border border-black rounded text-purple-700 p-1 px-6 min-w-[100px] uppercase text-xl lg:text-4xl"
              disabled={!publicKey}
              onClick={() => fundWithDonk()}>
              Fund
            </button>
          </>
        )}
        {/* <span style={styles.purchaseDonk}>Purchase $DONK while you still can...</span>
        <span style={styles.purchaseDonk}>DONK will be credited to your account</span> */}
        {/* {publicKey ? (
          <WalletDisconnectButton style={styles.walletButton} />
        ) : (
          <WalletMultiButton style={styles.walletButton} />
        )} */}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem",
    gap: "1rem",
    color: "white",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  balance: {
    fontSize: "1.25rem",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  toggleButtonsContainer: {
    display: "flex",
    gap: "0.5rem",
  },
  toggleButton: {
    backgroundColor: "#212529", // inactive button color
    color: "#ADB5BD", // inactive button text color
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  toggleButtonActive: {
    backgroundColor: "#512DA8", // active button color
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fundOptionsContainer: {
    display: "flex",
    gap: "1rem",
  },
  fundButton: {
    backgroundColor: "#4C6EF5", // button color
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  donkAmount: {
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ADB5BD", // text color for $DONK amount
  },
  donkInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  maxHalfButton: {
    backgroundColor: "#343A40", // button color for max/half
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  input: {
    backgroundColor: "#343A40", // input background color
    color: "white",
    padding: "10px",
    border: "none",
    borderRadius: "5px",
    width: "200px", // adjust as needed
  },
  purchaseDonk: {
    fontSize: "1.3rem",
    fontStyle: "italic",
    marginTop: "0.5rem",
  },
  walletButton: {
    marginTop: "1rem",
  },
};

// Apply additional styles to WalletMultiButton and WalletDisconnectButton as needed
