import React from "react";

export default function InputValue(props) {
  return (
    <input
      className="border text-black border-black rounded bg-white/60 outline-none text-sm lg:text-lg p-1 caret-purple-700 placeholder:text-purple-700"
      {...props}
    />
  );
}
