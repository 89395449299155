import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function LoginPage() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const { publicKey } = useWallet();

  useEffect(() => {
    if (state?.from && publicKey) {
      navigate(state.from);
    }
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-center gap-4 p-2 flex-grow my-auto mx-auto">
      <span className="text-center text-2xl font-bold text-text">
        You must connect your wallet to get started
      </span>
      <WalletMultiButton />
    </div>
  );
}
