import { Outlet, useLocation } from "react-router-dom";
import donkey from "../../assets/img/donkey.png";
import { useAuthStore } from "../../store/auth";
import { Loading } from "./components/Loading";
import { Navbar } from "./components/Navbar";

// TODO: need to understand whats going on here
export function DefaultLayout() {
  const isLoading = useAuthStore((state) => state.isAuthLoading);
  const setCurrentBalance = useAuthStore((state) => state.currentBalance);
  const setBalanceOnDatabase = (state) => state.currentBalance;
  const isAuth = useAuthStore((state) => state.isAuth);
  const user = useAuthStore((state) => state.user);
  const location = useLocation();
  return (
    <div className="flex relative w-full h-screen max-w-[100vw] overflow-hidden  flex-col justify-between bg-background ">
      <Navbar />
      <div className="flex flex-col h-1/2 flex-grow w-full max-w-screen-2xl   overflow-hidden lg:mx-auto relative">
        {["/fund", "/withdraw"].includes(location.pathname) && (
          <img
            src={donkey}
            alt="donkey"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[45%] lg:-translate-x-1/3 lg:-translate-y-[50%] w-full h-full object-contain -scale-x-100 max-w-[unset] rotate-90 lg:rotate-0"
          />
        )}
        {isLoading ? <Loading /> : <Outlet />}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
