export function MultiplierHistory({ multiplierHistory }) {
  return (
    <div className={`p-4 h-1/2 flex-grow rounded-md`}>
      <table className="w-full overflow-scroll">
        {multiplierHistory.map((multiplier, index) => (
          <tbody
            key={`multiplier-${index}`}
            style={{ height: "30px" }}
            className={index % 2 ? "bg-purple-100 " : "bg-purple-200 "}>
            <tr>
              <th>Ball Value</th>
              <td>{multiplier.value}</td>
              <th>Multiplier Hit</th>
              <td>{multiplier.multiplier}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
