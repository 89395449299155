import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import { Bodies, Composite, Engine, Events, Render, Runner, World } from "matter-js";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import "../../../../../App.css";
import donkey from "../../../../../assets/img/donkey.png";
import ballAudio from "../../../../../assets/sounds/donkeyNoise.wav";
import YourBalance from "../../../../../components/YourBalance";
import { useAuthStore } from "../../../../../store/auth";
import { useGameStore } from "../../../../../store/game";
import { BetActions } from "./components/BetActions";
import { MultiplierHistory } from "./components/MultiplierHistory";
import { getMultiplierByLinesQnt, getMultiplierSound } from "./config/multipliers";
import { Link, NavLink } from 'react-router-dom';
import "../../../../../App.css";

import { getInputValueD, getInputValueM } from "./config/multiplierVars";

export function Game() {
  const decrementBalance = useAuthStore((state) => state.decrementBalance);
  const incrementBalance = useAuthStore((state) => state.incrementBalance);
  const toggleBetLoading = useAuthStore((state) => state.toggleBetLoading);

  const inGameBallsCount = useGameStore((state) => state.gamesRunning);
  const incrementInGameBallsCount = useGameStore((state) => state.incrementGamesRunning);
  const decrementInGameBallsCount = useGameStore((state) => state.decrementGamesRunning);

  const currentBalance = useAuthStore((state) => state.activeBalance);
  const currency =
    "$" +
    Math.floor(currentBalance)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    "  DONK";

  const [lines, setLines] = useState(16);
  const [lastMultipliers, setLastMultipliers] = useState([]);

  // const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = false;


  const { publicKey } = useWallet();

  const engine = Engine.create();
  const engineConfig = { engineGravity: 1 };
  const colors = {
    background: "#5B5B66",
    primary: "#213743",
    secondary: "#3d5564",
    text: "#ffffff",
    purple: "#C52BFF",
    purpleDark: "#8D27B3",
  };

  const pinsConfigD = { startPins: 3, pinSize: 4, pinGap: 35 };
  const ballConfigD = { ballSize: 6.3 };
  const worldWidthD = 700;
  const worldHeightD = 700;

  const pinsConfigM = { startPins: 3, pinSize: 2, pinGap: 20 };
  const ballConfigM = { ballSize: 5.7 };
  const worldWidthM = 390;
  const worldHeightM = 390;

  useEffect(() => {
    if (inGameBallsCount) {
      toast.error("There was a ball in play... Please refresh to see your updated score");
    }
    if (!isMobile) {
      engine.gravity.y = engineConfig.engineGravity;
      const element = document.getElementById("plinko");
      if (!element) {
        return;
      }
      const render = Render.create({
        element: element,
        bounds: {
          max: {
            y: worldHeightD,
            x: worldWidthD,
          },
          min: {
            y: 0,
            x: 0,
          },
        },
        options: {
          background: "rgba(0,0,0,0)",
          hasBounds: true,
          width: worldWidthD,
          height: worldHeightD,
          wireframes: false,
        },
        engine,
      });
      const runner = Runner.create();
      Runner.run(runner, engine);
      Render.run(render);
      return () => {
        World.clear(engine.world, true);
        Engine.clear(engine);
        render.canvas.remove();
        render.textures = {};
      };
    } else {
      engine.gravity.y = engineConfig.engineGravity;
      const element = document.getElementById("plinko");
      if (!element) {
        return;
      }
      const render = Render.create({
        element: element,
        bounds: {
          max: {
            y: worldHeightM,
            x: worldWidthM,
          },
          min: {
            y: 0,
            x: 0,
          },
        },
        options: {
          background: colors.background,
          hasBounds: true,
          width: worldWidthM,
          height: worldHeightM,
          wireframes: false,
        },
        engine,
      });
      const runner = Runner.create();
      Runner.run(runner, engine);
      Render.run(render);
      return () => {
        World.clear(engine.world, true);
        Engine.clear(engine);
        render.canvas.remove();
        render.textures = {};
      };
    }
  }, [lines, isMobile]);

  const pins = [];

  for (let l = 0; l < lines; l++) {
    if (!isMobile) {
      const linePins = pinsConfigD.startPins + l;
      const lineWidth = linePins * pinsConfigD.pinGap;
      for (let i = 0; i < linePins; i++) {
        const pinX =
          worldWidthD / 2 - lineWidth / 2 + i * pinsConfigD.pinGap + pinsConfigD.pinGap / 2;

        const pinY = worldWidthD / lines + l * pinsConfigD.pinGap + pinsConfigD.pinGap;

        const pin = Bodies.circle(pinX, pinY, pinsConfigD.pinSize, {
          label: `pin-${i}`,
          render: {
            fillStyle: "#F5DCFF",
          },
          restitution: 1, // Set restitution to 1 for complete bounce
          friction: 0, // Set friction to 0 for no friction
          density: 0.01,
          isStatic: true,
        });
        pins.push(pin);
      }
    } else {
      const linePins = pinsConfigM.startPins + l;
      const lineWidth = linePins * pinsConfigM.pinGap;
      for (let i = 0; i < linePins; i++) {
        const pinX =
          worldWidthM / 2 - lineWidth / 2 + i * pinsConfigM.pinGap + pinsConfigM.pinGap / 2;

        const pinY = worldWidthM / lines + l * pinsConfigM.pinGap + pinsConfigM.pinGap;

        const pin = Bodies.circle(pinX, pinY, pinsConfigM.pinSize, {
          label: `pin-${i}`,
          render: {
            fillStyle: "#F5DCFF",
          },
          restitution: 1, // Set restitution to 1 for complete bounce
          friction: 0, // Set friction to 0 for no friction
          density: 0.01,
          isStatic: true,
        });
        pins.push(pin);
      }
    }
  }

  function addInGameBall() {
    // TODO: need to see if we can increase this
    // if (inGameBallsCount > 15) return;
    incrementInGameBallsCount();
  }

  function removeInGameBall() {
    decrementInGameBallsCount();
  }

  const addBall = useCallback(
    (multipliers, ballValue) => {
      const initialVelocity = { x: 0, y: 0 };

      const addBallWithDelay = async (multiplier, ballValue) => {
        addInGameBall();
        const ballSound = new Audio(ballAudio);
        ballSound.volume = 0.2;
        ballSound.currentTime = 0;
        ballSound.play();

        const uniqueLabel = `ball-${Math.random()}`;
        const ballColor = colors.text;

        let initialConditions;
        let ball;

        if (!isMobile) {
          // const minBallX = 300;
          // const maxBallX00;
          // const initialConditions = minBallX + Math.random() * (maxBallX - minBallX);
          initialConditions = getInputValueD(multiplier);
          ball = Bodies.circle(initialConditions, 20, ballConfigD.ballSize, {
            restitution: 1,
            friction: 0.6,
            label: uniqueLabel,
            id: new Date().getTime(),
            frictionAir: 0.05,
            collisionFilter: {
              group: -1,
            },
            render: {
              fillStyle: ballColor,
            },
            isStatic: false,
            startingValue: initialConditions,
            multiplier: multiplier === .3 ? 0.25 : multiplier,
            ballValue: ballValue,
          });
        } else {
          initialConditions = getInputValueM(multiplier);
          ball = Bodies.circle(initialConditions, 20, ballConfigM.ballSize, {
            restitution: 1,
            friction: 0.6,
            label: uniqueLabel,
            id: new Date().getTime(),
            frictionAir: 0.05,
            collisionFilter: {
              group: -1,
            },
            render: {
              fillStyle: ballColor,
            },
            isStatic: false,
            startingValue: initialConditions,
            multiplier: multiplier === .3 ? 0.25 : multiplier,
            ballValue: ballValue,
          });
        }
        Composite.add(engine.world, ball);
      };

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      (async () => {
        for (const multiplier of multipliers) {
          await delay(200); // Introduce a 200ms delay before adding each ball
          await addBallWithDelay(multiplier, ballValue);
        }
      })();
    },
    [lines, isMobile]
  );

  const topPinLeft = { x: 315, y: 78.75 };
  const bottomPinLeft = { x: 52.5, y: 603.75 };

  // Calculate the distances
  const dxLeft = bottomPinLeft.x - topPinLeft.x;
  const dyLeft = bottomPinLeft.y - topPinLeft.y;

  // Calculate the length of the wall (the hypotenuse)
  const wallLengthLeft = Math.sqrt(dxLeft * dxLeft + dyLeft * dyLeft) + 20;

  // Calculate the center position of the wall
  const centerXLeft = (topPinLeft.x + bottomPinLeft.x) / 2 - 20;
  const centerYLeft = (topPinLeft.y + bottomPinLeft.y) / 2;

  let leftWall;
  if (!isMobile) {
    leftWall = Bodies.rectangle(
      centerXLeft - 130, // x position (center of the wall)
      centerYLeft, // y position (center of the wall)
      20, // thickness of the wall
      wallLengthLeft, // length of the wall
      {
        angle: 0, // angle of the wall in radians
        isStatic: true,
        render: {
          visible: false,
        },
      }
    );
  } else {
    leftWall = Bodies.rectangle(
      worldWidthM / 3 - pinsConfigM.pinSize * pinsConfigM.pinGap - pinsConfigM.pinGap,
      worldWidthM / 2 - pinsConfigM.pinSize,
      worldWidthM * 2,
      40,
      {
        angle: 90,
        render: {
          visible: false,
        },
        isStatic: true,
      }
    );
  }

  const topPinRight = { x: 385, y: 78.75 };
  const bottomPinRight = { x: 612.5, y: 603.75 };

  // Calculate the distances
  const dxRight = bottomPinRight.x - topPinRight.x;
  const dyRight = bottomPinRight.y - topPinRight.y;

  // Calculate the length of the wall (the hypotenuse)
  const wallLengthRight = Math.sqrt(dxRight * dxRight + dyRight * dyRight) + 35;
  const wallAngleRight = Math.atan2(dyRight, dxRight);
  // Calculate the center position of the wall
  const centerXRight = (topPinRight.x + bottomPinRight.x) / 2 + 35;
  const centerYRight = (topPinRight.y + bottomPinRight.y) / 2;

  let rightWall;
  if (!isMobile) {
    rightWall = Bodies.rectangle(
      centerXRight + 130, // x position (center of the wall)
      centerYRight, // y position (center of the wall)
      20, // thickness of the wall
      wallLengthRight, // length of the wall
      {
        angle: 0, // angle of the wall in radians
        isStatic: true,
        render: {
          visible: false,
        },
      }
    );
  } else {
    rightWall = Bodies.rectangle(
      worldWidthM -
        pinsConfigM.pinSize * pinsConfigM.pinGap -
        pinsConfigM.pinGap -
        pinsConfigM.pinGap / 2,
      worldWidthM / 2 - pinsConfigM.pinSize,
      worldWidthM * 2,
      40,
      {
        angle: -90,
        render: {
          visible: false,
        },
        isStatic: true,
      }
    );
  }

  let floor;
  if (!isMobile) {
    floor = Bodies.rectangle(
      worldWidthD / 2, // x position should be in the center of the world
      603.75 + 60, // y position should be just below the bottom of the world, adjust as necessary
      612.5, // the width of the floor should match the world width
      20, // the thickness of the floor
      {
        label: "floor",
        render: {
          visible: false,
        },
        isStatic: true,
      }
    );
  } else {
    floor = Bodies.rectangle(0, worldWidthM + 10, worldWidthM * 10, 40, {
      label: "block-1",
      render: {
        visible: false,
      },
      isStatic: true,
    });
  }

  const multipliersBodies = [];

  if (!isMobile) {
    const totalDistance = 595;
    const distancePerBlock = 35;
    const multiplierBlocks = getMultiplierByLinesQnt("desktop");

    // Calculate the starting x position, which is the center of the first pin
    let lastMultiplierX = 52.5 - pinsConfigD.pinSize / 2;
    multiplierBlocks.forEach((multiplierBlock, index) => {
      // Calculate the x position of the current block's center
      const currentBlockCenterX = lastMultiplierX + distancePerBlock * index + 20;

      const multiplierBody = Bodies.rectangle(
        currentBlockCenterX, // x position for the center of the block
        603.75 + 25, // y position for the bottom of the pins (adjust if necessary)
        17.5, // width of the block
        17.5, // height of the block (assuming square blocks)
        {
          label: multiplierBlock.label,
          isStatic: true,
          render: {
            sprite: {
              xScale: 1,
              yScale: 1,
              texture: multiplierBlock.img,
            },
          },
        }
      );

      multipliersBodies.push(multiplierBody);
    });
  } else {
    let lastMultiplierX = worldWidthM / 2 - (pinsConfigM.pinGap / 2) * lines - pinsConfigM.pinGap;
    const multiplierBlocks = getMultiplierByLinesQnt("mobile");

    multiplierBlocks.forEach((multiplierBlock) => {
      const blockSize = 20; // height and width
      const multiplierBody = Bodies.rectangle(
        lastMultiplierX + 20,
        worldWidthM / lines + lines * pinsConfigM.pinGap + pinsConfigM.pinGap,
        blockSize,
        blockSize,
        {
          label: multiplierBlock.label,
          isStatic: true,
          render: {
            sprite: {
              xScale: 1,
              yScale: 1,
              texture: multiplierBlock.img,
            },
          },
        }
      );
      lastMultiplierX = multiplierBody.position.x;
      multipliersBodies.push(multiplierBody);
    });
  }

  Composite.add(engine.world, [...pins, ...multipliersBodies, leftWall, rightWall, floor]);

  async function bet(ballValue, ballAmount) {
    // const pendingTransactions = false;
    const pendingTransactions = await settleDiscrepancies()
    if (!pendingTransactions){
      const data = {
        ballValue: ballValue,
        ballAmount: ballAmount,
        publicKey: publicKey.toString(),
      };

      const url = "https://ht8z988w7h.execute-api.us-east-2.amazonaws.com/donk/getRandomSeed";
      let config = {
        method: "post",
        data: data,
        url: url,
        headers: {
          "x-api-key": process.env.AWS_KEY,
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        if (response.status !== 200) {
          throw new Error(
            "There was an issue calling the database... It should have been corrected, please try again."
          );
        }
        else {
          const finalMultipliers = response.data.multipliers;
          decrementBalance(ballAmount * ballValue);
          toggleBetLoading(false);
          addBall(finalMultipliers, ballValue);

          return;
        }
      }
      catch (error) {
        toast.error("There was an error making a call to the api. Please try again");
        console.log(error);
        toggleBetLoading(false);
      }
    }
    else{
      toggleBetLoading(false);
      toast.error("Please try again. Your network might be slow.")
    }
  }

  async function settleDiscrepancies(){
    const data = JSON.stringify({
      publicKey: publicKey.toString(),
    });

    const url = "https://ht8z988w7h.execute-api.us-east-2.amazonaws.com/donk/checkForErrors";
    let config = {
      method: "post",
      data: data,
      url: url,
      headers: {
        "x-api-key": 'idPjBI7gdP5o9BCJfpbbu9HQsyK2qgsF5kF1pXGr',
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status !== 200) {
        throw new Error(
          "There was a discrepency in your active balance. It should have been corrected, please try again."
        );
      }
      else if (response.status === 200) {
        console.log(response)
        return false
      }
    }
    catch (error){
      toast.error(error.message)
      console.log(error.message)
      return true
    }
  }

  async function onCollideWithMultiplier(ball, multiplier) {
    ball.collisionFilter.group = 2;
    World.remove(engine.world, ball);
    // TODO: need to make sure there isnt a situation in which a ball gets stuck
    removeInGameBall();

    const multiplierValue = multiplier.label.split("-")[1];

    const multiplierSong = new Audio(getMultiplierSound(multiplierValue));
    multiplierSong.currentTime = 0;
    multiplierSong.volume = 0.2;
    multiplierSong.play();

    // TODO: show all multipliers - not just the last 4
    setLastMultipliers((prev) => [
      { value: ball.ballValue, multiplier: ball.multiplier },
      ...prev.slice(0, 75),
    ]);

    // TODO: need to make this so that its only updating by whole numbers
    const finalBallValue = ball.ballValue * ball.multiplier;
    incrementBalance(finalBallValue);
  }

  async function onBodyCollision(event) {
    const pairs = event.pairs;
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair;
      if (bodyB.label.includes("ball") && bodyA.label.includes("block")) {
        await onCollideWithMultiplier(bodyB, bodyA);
      }
    }
  }

  function gettingHere(event){
    console.log("getting")
  }

  Events.on(engine, "collisionActive", onBodyCollision);

  return (
    <div className="flex flex-col lg:flex-row my-auto max-h-screen h-full px-5 lg:px-10 gap-5 pt-10">
      <div className="relative max-h-full w-full flex flex-col gap-2  h-max pb-5 lg:pb-10 xl:pb-14 ">
        <div className="opacity-0 lg:opacity-100">
          <YourBalance balance={currency} />
          { currentBalance < 10000 ?
            <NavLink to="/fund" className='uppercase hover:cursor-pointer'>
              <span className="relative z-1 text-white text-2xl underline hover:cursor-pointer">
                grab more $DONK to continue playing (click here)!!
              </span>
            </NavLink>
            :
            <div></div>
          }
        </div>
        <img
          className="hidden lg:block z-0 absolute bottom-10 right-0 w-[25vw] h-auto -translate-x-[0%] translate-y-[66%]  -rotate-90"
          src={donkey}
          alt=""
        />
        <div className="max-h-[50vh]  w-full rounded-md bg-purple-700  p-4 relative z-10 flex flex-col overflow-hidden h-max  ">
          <BetActions inGameBallsCount={inGameBallsCount} onChangeLines={setLines} onRunBet={bet} />
          <div className="overflow-scroll">
            {lastMultipliers?.length > 0 ? (
              <div className="hidden lg:flex h-1/2 flex-grow  flex-col ">
                <h2 className="text-xl  my-4 text-white">Last Bets</h2>

                <MultiplierHistory
                  multiplierHistory={lastMultipliers}
                  className="overflow-scroll"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="w-full overflow-scroll">
        <div id="plinko" className="w-full" />
      </div>
    </div>
  );
}
