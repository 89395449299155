const multiplierVariablesM = {
  40: [
    182.9706616880294,
    192.70544037715612,
    207.31092079310358,
    197.93698377573114,
    191.9516345915274,
    197.6741285711829,
    183.85399867149644,
    207.45148228542678,
    193.03477074294187,
    192.33328730235866
  ],
  0.3: [
    197.94386536372207,
    182.5855275904031,
    197.09922542512382,
    182.68648310182607,
    191.2392304742048,
    179.71021701543435,
    200.02695250655552,
    181.20050269945887,
    207.19513611999707,
    182.3995747679655,
    197.3095958045064,
    206.19746245541114,
    201.45747858190813,
    182.01967608653666,
    181.78778452172065,
    208.19095995795794,
    199.41018658590988,
    190.81392637661577,
    189.66924227392764,
    192.1845714993337,
    180.10325380304278,
    201.75623259548715,
    207.04554268403493,
    206.07853893515477,
    183.07544099249236,
    192.9364635200033,
    196.62018533523,
    205.30641173624903,
    179.55351311713002,
    194.13995062868338,
    192.9335885978194,
    204.7420032430545,
    199.13117748350393,
    185.636230419591,
    193.55434925710404,
    201.54488406442906,
    186.3004669469158,
    179.54667328627914,
    192.11122971278456,
    181.80810927052087,
    208.37581426724768,
    191.334502037556,
    185.76555958273522,
    208.11239326026623,
    198.05914356580698,
    188.3884209104678,
    192.02373551251935,
    183.0905227248541,
    188.44021100074391,
    192.46896097596002
  ],
  2: [
    180.83079491857725,
    181.76879011116608,
    194.9852422124794,
    190.30963825182022,
    192.69120440865612,
    198.9441591074491,
    202.51881069311307,
    197.75631616589936,
    208.60164686533608,
    199.44234662909497,
    198.14347897279927,
    179.92730170278966,
    179.74989161013076,
    184.58838180152765,
    208.88514359052454,
    184.71366437608393,
    192.48372392382578,
    197.50058036081768,
    193.72750043660395,
    188.66021362607975,
    180.12634259170238,
    196.49920656125866,
    197.76628525197665,
    204.25338422639078,
    179.38939095417032,
    183.9209077096884,
    189.91344680995746,
    192.6178909440918,
    180.59835603527955,
    189.45305694056816,
    199.17044216089658,
    187.96304027396548,
    196.99423804498022,
    182.4992896566637,
    181.10445438306655,
    205.51665043075334,
    195.34839585920076,
    182.63191377225095,
    182.91298631683406,
    195.88068695860372,
    204.86885678048333,
    182.69573918046711,
    203.82375560554252,
    186.00902852461357,
    193.0881091702744,
    191.07560354723944,
    192.6871975159721,
    179.44340434804204,
    190.39282685394232,
    194.38966913381475,
    183.89351321016852
  ],
  0.1: [
    201.61887733899366,
    200.01178278465488,
    189.60386227939762,
    182.12846285517003,
    205.7961334830528,
    201.40350367809344,
    194.85101579554401,
    203.92692573190132,
    190.38133971140002,
    205.70354357490615,
    189.988159300476,
    179.88979771691152,
    189.84590159139483,
    192.64317082476413,
    198.07971377132935,
    181.7284071191039,
    182.5978468511775,
    182.31946380869374,
    180.99937242685678,
    198.11823725619567,
    199.00510027718747,
    208.3557379278202,
    202.56264346614145,
    183.02180393189576,
    201.61285292266427,
    203.32755943289362,
    191.85574136435278,
    179.99476298836495,
    192.28983689055698
  ],
  5: [
    200.57131806952776,
    208.82477408983002,
    182.54884631076033,
    198.40779516545396,
    198.9966080840792,
    188.85606970749225,
    186.05906111758804,
    206.6669034800152,
    183.74933561399808,
    179.12478146077876,
    192.63946477942596,
    200.66879354018732,
    186.62607006218263,
    198.98574064556993,
    198.011281603871,
    198.7750049056256,
    202.0822387964924,
    184.88720214926204,
    192.6655429192326,
    204.00511571724908,
    180.88348213998924,
    180.67352723616563,
    180.82012323930124,
    186.21437590129386,
    186.19032846351917,
    179.1838430836179,
    197.13577305526113,
    191.94548911774314,
    191.11336709153971,
    181.76636923333376,
    185.84781062323694,
    206.16606458923476,
    205.65843051221228,
    197.6199218532462,
    189.25224317243612,
    199.65660797637963,
    191.42076026361488,
    180.37511519782097,
    190.8378660927221,
    179.71213235820295,
    188.36398465319905,
    193.01505225545134,
    193.32260513777175,
    198.2229852272205,
    189.24405344748624,
    195.74826827021343,
    180.15796758340036,
    195.68924553783128,
    196.10455882828154,
    206.61283918034889,
    185.6292661264295,
    187.815343555529,
    206.14898436893878,
    189.2816206065241,
    196.25732395288694,
    201.62680967154114,
    207.92939257994973,
    198.2973658167755,
    190.829123548313,
    182.16335648561054,
    192.6711185517837,
    183.57133705373008,
    192.58323270204724,
    198.95986183320687,
    189.93692084336575,
    190.3503652105517,
    197.2850617074916,
    206.8198177539549,
    190.48934081620183,
    180.3284130336242
  ],
  0: [
    183.9646229939355,
    200.35771735130768,
    201.36066654768788,
    183.05295513282636,
    199.83187672793454,
    190.08894984195575,
    192.99833745044185,
    191.57395740281396,
    182.30691310834322,
    197.67435389746097,
    179.98534163892978,
    206.8579929918079,
    191.19965761015035,
    197.69867160940882,
    186.36720279499528,
    192.9076403727983,
    198.19215152031006,
    203.35697940350147,
    201.11264027413446,
    180.32618105588352,
    199.9296488345433,
    187.75883985155545,
    192.5064105443941,
    191.91930131422737,
    180.08741792337932,
    187.69695254429712,
    207.8482601973551,
    208.6683116770984,
    203.73224297222853,
    186.5338833933286,
    192.36090076013755,
    183.74275307120888,
    187.7881417169827,
    204.18277659100855,
    190.1363455694355,
    192.25025909617239,
    179.3169156084358,
    181.05512910176935,
    191.05639137255832,
    198.0866155928216,
    182.39673485812702,
    198.54539001620273,
    191.99078219351134,
    207.17825410349397,
    204.05394611098086,
    192.2124261987741,
    185.56270696178458,
    191.1797479378559,
    182.3462877620916,
    201.10235062162897,
    190.727756338065,
    202.11536022853764,
    192.88896435818148,
    206.85217370938022,
    193.18283861830275,
    184.3651596986146,
    203.88501118130762,
    192.1107521935425,
    202.60429626622013,
    182.17241578693674,
    185.95206358359547,
    203.60437776953654,
    204.32378995604168,
    189.89076073315613,
    194.8942262189061,
    187.45389853522326,
    199.32692880585094,
    191.93867277935223,
    190.71893835041345,
    186.01539830795076,
    195.31247905011548,
    190.3633279512117,
    183.99852781622255,
    187.34133277878587,
    199.6000763124925,
    201.5566724745891,
    203.2253112889414,
    203.25793367117384,
    200.27777995822427,
    206.62130683632185,
    205.75926389513577,
    193.50544334523943,
    184.0557710678535,
    183.51053710530928,
    196.93473477655795,
    195.215608085843,
    186.1338325645257,
    190.95582912539905,
    193.98991942847272,
    184.96744435311052,
    192.88074427376745,
    207.76749272013694,
    183.10788209970056,
    183.94680695770296,
    182.17732943962997,
    192.1127871996266,
    197.95871044898584,
    197.62501670986177,
    196.91550260886888,
    195.83734909221872,
    206.97870961334309,
    180.30109376398926,
    198.61975591304213
  ],
  1.5: [
    200.3882371817591,
    205.58218057502177,
    179.82597292931825,
    185.23309914257104,
    202.58804437622817,
    204.19026704059107,
    189.9171128866078,
    196.46299936885967,
    204.62331352033306,
    184.11366758361902,
    198.50233632184438,
    197.55383441904374,
    182.14877394654437,
    204.31882245138996,
    188.2034835198744,
    189.15582163117918,
    186.38194181095025,
    190.78508822147145,
    197.00265858206393,
    202.068593767414,
    179.71158044616084,
    192.39865377238982,
    181.20632332848663,
    194.24467145148444,
    199.68123274653908,
    204.81200137219614,
    206.5051596646225,
    180.57717596507422,
    188.20991306529993,
    191.5290281028989,
    189.40119179743377
  ],
  0.25: [
    183.39809080005992,
    204.1637287938514,
    200.54361598062545,
    198.5261385327432,
    202.4810151241475,
    207.0318292621743,
    193.82042757563465,
    184.5049965044914,
    193.59626949750717,
    202.34358261192645,
    179.39377904401303,
    206.3995850235755,
    189.68501788915884,
    184.6748332590637,
    194.40568097740604,
    201.2713407508259,
    183.91153833456292,
    180.41158135148,
    187.24306795672533,
    186.9559035956497,
    179.8354345379512,
    179.04306666437165,
    187.71718312737656,
    204.99515413532362,
    197.09966175593124,
    188.95818050519884,
    183.37450685338274,
    202.541765426566,
    191.8903235008654,
    204.56662353334715,
    189.95820709209636,
    191.76109794973445,
    200.71925621059535,
    186.18920511065292,
    185.81824115955038,
    185.48493418713826,
    189.68355692284786,
    189.28535618137744,
    200.87502868957486,
    190.46611262747967,
    189.08801705606047,
    188.10753650134262,
    181.20305668976346,
    207.28500372303583,
    193.81909146124008,
    185.73520659633346,
    186.4963227201567,
    204.769033819514,
    197.19083563775376,
    198.50238797088352,
    208.19748912655555,
    194.23838709840302,
    196.13754819252907,
    191.18830724873052,
    198.48239479418262,
    199.19673163812428,
    181.43901118993784,
    185.06594752095614,
    199.34876902716275,
    186.18913228686276,
    202.90491060267246,
    201.13560980960577,
    185.64761419608294,
    185.78962357556495,
    180.2699755645988,
    186.19517016357344,
    205.7150886457149,
    187.22101381900137,
    205.7919185688512,
    193.82650590742875,
    180.25003714331845,
    196.48768480587023,
    206.4906588776197,
    195.67796611760195,
    205.60164784919968,
    208.16054737403158,
    193.51795905869568,
    183.57260029396818,
    191.63225179673026,
    194.26288292367383,
    198.93527332917185,
    203.15967797903397,
    189.15970063293344,
    179.8535101713694,
    207.9565808021512,
    180.47536377389173,
    204.6751727055208,
    194.74910069255264,
    190.10486312180328,
    198.800989206652,
    193.62427231645114,
    207.8051993942983,
    182.16858843249094,
    197.4589673374218,
    203.06447532390212,
    205.57021068065455,
    188.15340489189464,
    201.20386704693277,
    189.64467481639088,
    192.75006922135992,
    190.88748138561562,
  ],
  110: [
    186.53587030559592,
    203.72716162903762,
    204.05133577409222,
    206.5396441523728,
    184.43563532260438,
    198.52927919006717,
    186.7532149433938,
    199.5153645773825,
    182.31527273879482,
    204.4112112863803,
    194.81043904420767,
    191.6029473111368,
    208.7407223339731,
    199.86393814888638,
    198.4778718930942,
    185.56351502599122,
    187.8556085637394,
    193.9270241626286,
    180.96111121102354,
    204.23065898960454,
    191.53829647703014,
    197.88835729601323,
    183.30156430515842,
    205.6907870431531,
    188.75318164876194,
    202.99985520357035,
    183.24842913391555,
    203.66592084222077,
    200.76839013734588,
    194.1720699688953,
    200.01128270037987,
    204.14885560746498,
    185.91386284613347,
    206.63977045458205,
    203.18483745419024,
    185.20461492953933,
    187.64685242490734,
    190.00434967482738,
    186.32139589172067,
    185.5439621803589,
    196.0824151949504,
    185.8119357452861,
    204.46062146567323,
    181.5114750882867,
    201.80786774006387,
    199.02980342101404,
    187.5443393996864,
    192.24747497839158,
    182.74013300726517,
    193.15884846607426,
    179.44058664561146,
    205.22302106404817,
    192.46981293489324,
    188.2694815028133,
    196.00571785103492,
    203.56804522093435,
    188.18439011063955,
    183.07578612919687,
    203.90940356388003,
    190.77165726808713,
    189.82864689351055,
    184.97702186616314,
    199.2410558107454,
    205.7276390583535,
    183.03795359762114,
    196.1997792648595,
    204.92272330994305,
    203.2369203704485,
    193.54020699352762,
    189.0215513941577,
    202.5841254034133,
    187.30959239920327,
    206.08311967411518,
    192.48206404898528,
    184.08164022013256,
    184.00440487063247,
    199.61722716077148,
    194.19076029668017,
    190.91544851911257,
    188.57831631699892,
    208.96777435328886,
    189.53382866808425,
    185.46851761069505,
    189.53224689902967,
    200.68971626619174,
    199.2038395637783,
    208.37738791417408,
    200.3536112858331,
    205.78729969193176,
    206.6490710610708,
    195.6978648650765,
    179.09607249099898,
    185.35591062650232,
    206.68849029506626,
    204.92310708815378,
    199.84358314690223,
    208.33467542714033,
    198.15787393307687,
    191.46327507835505,
    198.38971034244383
  ]
}

const multiplierVariablesD = {
  110: [
    332.4627578643667,
    318.2318267460089,
    363.7589828895758,
    311.62794204451865,
    380.4269641913776,
    319.68519079941007,
    360.3612994930796,
    300.5333198980137,
    380.1370683786912,
    362.98242647720747,
    344.4105198917467,
    398.76990066438594,
    360.6342862775235,
    331.0928489835596,
    361.60311103439864,
    385.2926954044643,
    300.8883787736743,
    383.8038229238866,
    318.6983396325177,
    352.02802313103734,
    377.1740806297746,
    370.96630815876824,
    313.41494850946935,
    357.7813694257277,
    317.7453759195948,
    355.45540627548195,
    346.62276928923706,
    377.8872976547802,
    341.5253298191889,
    327.64072191951004,
    320.72374439959503,
    327.13448886410134,
    399.2778714426788,
    336.34788324859494,
    345.3814529047909,
    378.06530073203686,
    321.06922361146536,
    320.7708986100966,
    304.7175612145417,
    331.104836703454,
    340.7029083973701,
    361.1447043960989,
    372.94568309176236,
    366.20736339173203,
    332.43007781336104,
    300.52245454149124,
    377.88643889050445,
    395.1479583388177,
    340.36711674487844,
    350.79137677913354,
    321.46661055202287,
    350.31303924942483,
    332.30891966461644,
    305.01112201095754,
    391.01971320629934,
    340.5236147818559,
    375.41917478158217,
    321.9236994808535,
    394.09231948641457,
    356.61346419444817,
    349.25281910478793,
    370.2816953479094,
    345.55180563174986,
    366.3638063943098,
    335.40528013089994,
    318.74259349980366,
    351.74808129645044,
    376.7099440148894,
    321.62430548925795,
    302.21992031708993,
    370.9298004001424,
    374.3585344626804,
    388.72086838190967,
    341.05172873901085,
    397.2880524195291,
    385.9245140271706,
    362.67891216904314,
    321.57805893796007,
    386.19764591336707,
    303.7852653562396,
    353.505505592413,
    339.8082401922268,
    345.2013591785121,
    332.7688824416009,
    319.4654152132348,
    355.2816648026882,
    383.0912169820494,
    399.81890253800293,
    350.9636073616356,
    311.1287778207067,
    356.76638927963825,
    366.8896245735751,
    370.1100044443105,
    357.9679012099911,
    308.201586206391,
    338.0978888857329,
    358.1504843445396,
    345.7458396195857,
    356.700992258252,
    383.44177437211323,
    340.37723804497415,
    305.9520803305839,
    333.33081242335834,
    364.56378089942245,
    323.7973852713918,
    325.87415102785525,
    347.5807657064786,
    304.0964557859141,
    385.89811155584687,
    364.24242413905245,
    329.5698612694911,
    395.45817469343797,
    398.8124593212126,
    376.56527631938764,
    333.31140049998663,
    322.16596076729815,
    381.65933462226764,
    366.3273422781377,
    316.0404491344992,
    328.9635708103701,
    322.5911287314315,
    319.48704006253865,
    358.97237623067593,
    373.7606057270964,
    391.72608516899004,
    301.1761766524393,
    360.31919381383983,
    361.0208218835165,
    325.545969209806,
    318.60523829419895,
    332.8926384449012,
    321.5652025351187,
    375.2428733982209,
    321.06107338788047,
    339.3816215228619,
    397.20795556342034,
    385.05525034744875,
    320.85615144552577,
    342.69195156202437,
    318.0149200120935,
    344.566523218724,
    394.6600570713631,
    374.25637199734365,
    371.40166438710696,
    322.8691379023842,
    399.0675885539895,
    344.909839386173,
    394.90750555912433,
    377.25383592141014,
    363.2385398621043,
    321.9107726498399,
    343.625439189394,
    366.98715895217197,
    374.0897242699035,
    370.96224950647576,
    321.74228435584655,
    356.1078572346928,
    324.30552707265974,
    375.5804709931077,
    335.9787377797892,
    362.54657313632714,
    392.61961880946745,
    379.5052772612113,
    394.8778382856616,
    370.8590226783641,
    361.36365866917845,
    335.08691412934627,
    358.77501322908336,
    322.8198337978899,
    333.9027644828218,
    373.1218400818084,
    363.34057975963935,
    345.09347806743114,
    310.44636929395546,
    336.32356777097834,
    316.0952288391543,
    322.7604661654371,
    369.77901010962887,
    352.27582304118675,
    363.7646062282463,
    363.12657280697977,
    322.53201121673794,
    364.20000321328456,
    350.5379945361551,
    373.4150892896182,
    353.55152543349647,
    306.32157078151755,
    344.5205917002708,
    355.2967720234315,
    339.3531563687785,
    328.0282968984238,
    364.8292734677685,
    307.95469671537205,
    322.06224108221716,
    396.12969143103084,
    317.8143956887121,
    352.8204450090232,
    319.4743466609984,
    331.733973389036,
    371.9867245595368
  ],
  // 24
  40: [
    309.98174969792296,
    389.9765506496615,
    387.5638973032772,
    387.1922633574604,
    312.3499194108845,
    387.49567467111376,
    306.7002178845994,
    312.5100612846144,
    387.4569843280661,
    390.02252436962,
    387.4645405317948,
    310.0573731103474,
    312.5525760272349,
    387.6507709572114,
    387.50261338202233,
    310.24069023239747,
    312.34558565875375,
    306.7004182296857,
    312.48429913028383,
    312.79738059147815,
    306.7006184537519,
    312.4255013400297,
    312.49280085891166,
    312.34353715361425
  ],
  5: [
    389.8237065050101,
    341.71854633255936,
    386.76340816399653,
    388.2813483521638,
    390.05051317082246,
    369.6839613750729,
    387.9305083005422,
    315.6911222159431,
    311.7224245499708,
    305.1379074774926,
    387.8908066303808,
    386.80997356278107,
    392.1504249266287,
    361.3512833310351,
    392.1717276321177,
    312.24610632282264,
    314.6684395061101,
    347.0670356099134,
    312.9422154497335,
    308.4336163872631,
    346.41136689865044,
    312.338067141994,
    310.6786033088046,
    367.0273840698684,
    306.8361768146194,
    392.80014229441304,
    386.86046357881867,
    392.37890949036944,
    362.2686475049584,
    310.5445590940689,
    381.33985179379914,
    314.65268123245926,
    388.88057887800124,
    387.4799093306275,
    388.6141791915689,
    306.22791337091263,
    380.91735423736554,
    373.9449859139987,
    390.68568616343623,
    379.11462589478447,
    309.1464191844086,
    312.5749187302284,
    387.92815526413926,
    313.0223489246421,
    312.5351624556136,
    312.07018816009423,
    391.33591162009645,
    358.37746870961735,
    346.41587642908416,
    390.3696740178077,
    303.4990310133273,
    389.36874613097183,
    388.33120975522195,
    336.9860888767966,
    389.3292588056887,
    342.4608144470894,
    303.5731154518634,
    307.622809449981,
    311.96704080730086,
    312.59895661464844,
    309.6661155788032,
    307.5465352774339,
    312.1370123400937,
    371.4806703938103,
    373.87549248541524,
    378.052546520756,
    315.6146368206354,
    344.96787270908976,
    310.28412798016154,
    389.32850656188504,
    341.71136193720173,
    390.42088013029894,
    310.10446491019405,
    387.8541410271661,
    387.71012733613816,
    396.9362151477877,
    384.7817666713533,
    368.47053397570465,
    391.97944707326786,
    346.8830445987329,
    311.9698652809684,
    305.8801346423358,
    313.7818360878126,
    310.3383334071639,
    385.27380199407435,
    375.33726284007014,
    313.64862898649244,
    348.23029874128923,
    338.2377809178331,
    301.5708931674379,
    310.1594669357334,
    386.7562366772303,
    348.6030425537195,
    390.43605066478665,
    387.9269547841096,
    369.93827354457403,
    390.3347510326418,
    386.07093482729726,
    359.14437313962105,
    303.8534495914954,
    386.8935266808685,
    312.0460313252456,
    312.3193870746142,
    352.62393215783777,
    354.3889959317577,
    387.4211625488599,
    308.0568754895579,
    391.975977194626,
    391.8757531854526,
    328.5485034925788,
    358.688870276902,
    392.53894552929387,
    312.378465863686,
    346.1988401324172,
    308.04038735477417,
    302.4045418791345,
    341.293574574952,
    386.29171105342675,
    351.32940255269574,
    393.09934435519085,
    394.51023033520534,
    324.9584625401615,
    376.03013632147645,
    309.89480635061983,
    345.03718299934445,
    323.946740829803,
    383.4798821092313,
    367.6019072438827,
    387.1174250613217,
    307.8635793407915,
    348.40025988373577,
    306.49221096383326,
    311.88657646273543,
    389.81718651150135,
    388.97907612352475,
    307.6728981611884,
    393.9405512406214,
    393.755241739946,
    390.5602388621302,
    396.8656038540424,
    312.3053996794571,
    308.5170924080253,
    324.442153030286,
    310.63739063197784,
    393.32240917621584,
    309.5516225140993,
    345.6737531408369,
    313.15091944044644,
    328.6621230069619,
    314.8146908385118,
    342.1599547850983,
    385.6056349290095,
    392.1019199056034,
    366.35007195289523,
    307.78830670372264,
    391.2725201511042,
    387.98888989998534,
    389.33012838418665,
    325.83254246745776,
    386.13139228874365,
    397.0435329975716,
    397.74262847986654,
    392.24526231098866,
    325.4239327005083,
    311.6453163525899,
    348.42006550336515,
    367.33583156804985,
    374.8944217101405,
    306.7363067128666,
    387.8459256838651,
    391.8509677604561,
    312.5238195071901,
    390.17286065636586,
    391.75358253479106,
    309.22474445701346,
    390.04818684386873,
    352.36086244935854,
    309.79004046901775,
    325.7674318140417,
    375.4478535584349,
    312.30691208177313,
    333.237710346749,
    385.9587933503584,
    368.1681207262816,
    311.761823641075,
    312.6500856118389,
    389.5063695225082,
    390.3738812509671,
    354.2796934662738,
    387.1575721750075,
    312.3142838980481,
    338.4938255364483,
    391.9477992302687,
    396.4645228618634,
    397.6742771619416,
    393.07270816673747,
    375.6602999938009,
    387.1936712657694,
    357.89664847807273,
    387.6802978351926
  ],
  // 172
  2: [
    310.2663922019404,
    312.05456403828055,
    390.6107672143415,
    392.8812424064476,
    312.07662229776844,
    387.56998716536356,
    386.9426099401024,
    389.9910076151991,
    312.82649827217585,
    308.6637101475316,
    387.6123333525136,
    387.60049529505665,
    387.61063156069685,
    310.1834277343606,
    387.54188663255536,
    387.6464175893668,
    312.4403121670315,
    390.00853235549334,
    310.26760925726484,
    387.57071835635327,
    312.8381507561222,
    387.489120809888,
    391.9467702600508,
    310.06732754011284,
    310.0080292480294,
    388.9552851037504,
    387.6475177032052,
    387.3865069194537,
    389.50665723406314,
    310.0039195157877,
    314.55144796466226,
    387.51937872774903,
    392.8874427220934,
    389.62776456643564,
    306.69839672676574,
    312.55184720656155,
    312.5338856196871,
    307.6058651678391,
    389.9904596549029,
    312.0176460374843,
    311.6934644960741,
    309.8916807508496,
    312.8041527939584,
    310.0341077587612,
    312.4524245555056,
    312.0833601375311,
    310.53798945655757,
    389.81364965205944,
    312.7935887019638,
    312.3497273134319,
    312.7816818542944,
    387.5865720004797,
    387.5399865209646,
    310.0121644966762,
    312.39284432022816,
    387.5474637741182,
    387.49241598566306,
    387.5906784078039,
    387.71409702413496,
    391.94265603082675,
    387.847139766832,
    312.4527303404466,
    312.42705243639574,
    386.39652223110215,
    387.46245817169074,
    387.5399865209646,
    310.0121644966762,
    312.39284432022816,
    387.5474637741182,
    387.49241598566306,
    387.5906784078039,
    387.71409702413496,
    391.94265603082675,
    387.847139766832,
    312.4527303404466,
    312.42705243639574,
    386.39652223110215,
    387.46245817169074,
    310.06780018393147,
    370.52617482889764,
    387.50794898820914,
    389.92385319335085,
    390.0924541008795,
    387.6595490689908,
    307.6180933120503,
    312.5325860549488,
    312.35921372047903,
    390.0004694113235,
    309.96562131325345,
    390.00445644097096,
    392.54209591455907,
    309.90426382875125,
    387.9285291269198,
    312.1452829429802,
    388.0372388680401,
    312.5736288988708,
    389.9511271693747,
    387.923887592814,
    312.4055227999988,
    389.34959045267874,
    312.6130192563165,
    386.27725119058437,
    389.4121831183968,
    387.4538102919995,
    348.47327479738266,
    306.6990566471396,
    308.0106340701166,
    347.75424722114803,
    309.984452702851,
    390.0184682581382,
    389.75831252941305,
    312.58094843350966,
    387.5644251329536,
    391.94519798718954,
    312.5104233201761,
    307.6367507274359,
    307.67069774791463,
    389.3944780668811,
    391.33630785457126,
    391.8565456401567,
    312.8079405633037,
    389.97896919765793,
    312.7990978111661,
    312.3885730704576,
    312.0391974277085,
    312.2377547693707,
    382.24388813962196,
    387.6452415749018,
    310.7892787739193,
    312.34837394350956,
    312.0716540061353,
    387.4910490038817,
    387.49784073778017,
    387.5066553219012,
    313.4111736490175,
    391.9191674607142,
    312.8018289350109,
    312.340962236661,
    387.60518166871543,
    309.284327561513,
    387.55554713107404,
    387.50247397400847,
    354.6931482120685,
    387.652094567878,
    312.59817883274377,
    387.9848520593999,
    387.8813808628679,
    309.9301214156189,
    387.4896445016981,
    387.56541754374814,
    312.8592476037568,
    313.0880271726096,
    312.80168832278343,
    389.9246922284816,
    387.2074169919541,
    387.5720517145569,
    388.00374669587484,
    312.5886962041731,
    390.22375624464814,
    387.4486038578925,
    397.01217284002394,
    310.63283970038236,
    312.48450586139063,
    386.56748191434104,
    312.48830829494784,
    309.98755252656116,
    387.5044926843499,
    312.3997517592765,
    393.2795045534149,
    312.5406648952113,
    387.4615340902186,
    312.4882460686273
  ],
  1.5: [
    394.0975976947326,
    390.67736998936584,
    310.46218225962656,
    303.5680643178392,
    349.14750350651235,
    348.0642354061487,
    370.65180650498223,
    390.4955569883101,
    345.5954963982838,
    354.05254376871045,
    315.7496293623033,
    357.88460212469795,
    364.2409041794095,
    372.0218644776015,
    328.7865454902916,
    314.59710841798693,
    336.0216457042231,
    390.7854938633054,
    325.7521725522361,
    331.21615348500166,
    370.7215039179131,
    388.2878099512272,
    367.1638823306157,
    324.4923437588661,
    392.14360940780455,
    309.93847720599865,
    345.19680719595914,
    325.07216181511444,
    387.3120867908213,
    354.08656596153025,
    391.3872239212727,
    386.6550780203447,
    333.7574126662546,
    393.2976908189663,
    303.49088778945946,
    367.03820791348977,
    375.17724471672034,
    316.48847428178556,
    341.6484706464256,
    306.81139936392503,
    324.36043610152456,
    338.88498163701166,
    399.26813209705756,
    316.53456648721493,
    366.4489119281176,
    342.67962223396955,
    356.81449909124206,
    329.83780912752405,
    383.8203937045439,
    318.6284238037531,
    366.9092779405395,
    391.52695326254747,
    379.9253955852255,
    304.7183731074292,
    323.7781547761359,
    300.04312678007113,
    367.5607135403223,
    306.8496375384508,
    389.2632527831383,
    309.7968889340272,
    358.9800044386636,
    354.2837105898969,
    361.8019003798742,
    397.8377930057195,
    310.7626482594449,
    388.33177290876426,
    394.0306683981313,
    318.58809994990946,
    340.4166641498677,
    329.3121551616082,
    397.499327255207,
    316.653168902003,
    355.96980759297935,
    308.29714819568335,
    333.57037901589024,
    337.89737224551624,
    375.62524370248946,
    327.9495514558669,
    388.603491372296,
    369.48969174635903,
    329.1531095793856,
    358.33827256584357,
    375.43127800903886,
    378.8342012144288,
    309.8603013637225,
    358.866405826451,
    321.25052582376685,
    302.56478159373665,
    375.6857746362679,
    356.89833924320106,
    350.7182638558867,
    337.77264738902306,
    382.21850939616434,
    384.2740521918878,
    393.35874842454496,
    373.9723300076988,
    330.2263617065169,
    323.67821567570456,
    386.2525252232016,
    346.7249966882915,
    305.63775139420545,
    381.880772236168,
    379.1616220971031,
    341.42107553234575,
    323.6049037765706,
    302.09516031482184,
    338.42331393841766,
    357.5447548688513,
    313.9215732589998,
    365.0857545973167,
    392.80051193771163,
    343.1876532248602,
    369.6783723149864,
    391.3461359214143,
    367.3858223332268,
    329.09456196455284,
    383.2921427422508,
    391.76560785166464,
    370.37158476747214,
    374.82964883195086,
    314.7034676601986,
    353.77167321222146,
    331.0286974559393,
    397.58315989077056,
    397.4224244975919,
    318.0129319755294,
    383.9557864266774,
    307.64872504440365,
    378.104962864531,
    398.15544767763487,
    392.978539463471,
    300.4477183867769,
    323.5674503004094,
    391.01827669575,
    398.8101948286941,
    302.2330526983054,
    344.4009441582556,
    395.64063157861796,
    390.9951106242859,
    300.3463512566758,
    392.42816353726533,
    332.02178586963544,
    312.2060018152923,
    350.0561322289506,
    315.13660732392214,
    332.82856497837776,
    368.0544895179827,
    349.30861559689015,
    386.89857580850344,
    336.447934353669,
    348.0100678729132,
    325.0539238420862,
    319.1082507027933,
    304.2331689941555,
    380.4416736767098,
    363.40240210897673,
    373.42681637921197,
    371.5951195317041,
    374.5419865283732,
    399.72348095271025,
    305.20621014197076,
    318.04308976817083,
    323.1016547692769,
    365.5980380470025,
    380.5836661902739,
    361.36961850085805,
    303.27799110115456,
    330.1763834206218,
    388.4683369958692,
    350.2530115643025,
    370.684276153292,
    391.6903100292568,
    373.87454932629464,
    300.03606695149165,
    378.29961758145544,
    308.61961377128944,
    382.54945311055695,
    314.7776762967446,
    397.3160810111527,
    359.56577211378914,
    394.2833135876794,
    323.1757453711653,
    317.69410561634044,
    320.32096927453733,
    388.4841529621214,
    319.7022856740026,
    351.5616274250713,
    392.2714296420376,
    356.56236016118004,
    313.65377812012343,
    396.99479275283124,
    377.4869342714177,
    344.7083229786981,
    302.5450168557592,
    343.967019759815,
    354.5077378830988,
    385.12610296153866,
    396.59106056117787,
    359.89554474323364,
    331.633294704167
  ],
  // 55
  0.3: [
    390.0024972073219,
    389.9882751796745,
    396.51552893196174,
    390.39693539986934,
    393.30181678403244,
    310.1008990755882,
    312.0871843085205,
    312.3992642546912,
    386.53629529596014,
    387.48803529251927,
    310.0334111007946,
    306.70029685980677,
    389.70371680253004,
    387.5532253977451,
    310.02860098601815,
    387.5484540714727,
    312.432871828367,
    387.2079831497289,
    310.0147178165822,
    310.0239564022362,
    312.43897586402994,
    312.4935923387485,
    311.5592296074423,
    387.6554576046212,
    312.45059117565194,
    310.23471668961406,
    312.34413534755896,
    312.4823022608368,
    311.9623731617616,
    386.9115877063799,
    390.0228574437373,
    310.24990990146233,
    312.81537548951826,
    312.5143321891525,
    390.4484874927813,
    312.4873923652454,
    312.43603894112437,
    309.97712119291157,
    324.36173789275375,
    309.90529128219555,
    309.9782625943225,
    387.65370677084235,
    306.69946065219887,
    387.56541081696247,
    387.2089717562055,
    312.43520449924154,
    312.38560353469563,
    387.4963206241532,
    387.5113027790109,
    312.03601531519473,
    312.4811679356262,
    312.792512741887,
    312.4898920092182,
    312.3497803439286,
    387.6448600450145
  ],
  0.25: [
    370.7730360032924,
    314.5917701567948,
    334.8686400414764,
    350.49581853684214,
    351.4066687932842,
    326.99056848962954,
    373.2820096649747,
    346.1228868730318,
    323.62043344374064,
    362.70724059746703,
    372.7125378003463,
    364.2760736648721,
    346.43315456898944,
    380.4064692266395,
    321.08176652462214,
    324.7774658301734,
    342.3045533080525,
    350.84365205016655,
    350.6088411653797,
    336.6732632616414,
    376.6435394604971,
    333.5263464728004,
    340.0166402113495,
    338.05956841570867,
    314.1847226653543,
    338.5128595165668,
    383.3901387496108,
    375.51451917603964,
    395.55182309114105,
    393.3907547089286,
    342.7865084318018,
    395.8986447665304,
    300.61808828251856,
    383.77347759618135,
    361.33958940152786,
    371.98145059218956,
    303.1590574819793,
    376.63686047151856,
    320.8847540833475,
    398.7121405245181,
    362.12149762670305,
    395.20812572063,
    395.6841796014417,
    366.6473465944199,
    372.1231665812512,
    373.27506458022964,
    314.5913337659984,
    349.9810763530604,
    397.8933653118221,
    369.6226719209584,
    349.0897191266499,
    315.2380325812695,
    383.99386887032233,
    366.50004071049415,
    308.7522087459697,
    304.49141849030167,
    358.66262005572486,
    369.5268574098566,
    365.7277012167191,
    363.5773686560195,
    372.0304326565178,
    384.35397995186037,
    372.776112336506,
    372.6139930529754,
    383.5067739135511,
    331.3749594124148,
    306.6330362344661,
    305.7601836859817,
    386.31959965417826,
    365.3252010361616,
    380.97578740337735,
    364.5603000786187,
    322.82054342764167,
    376.4927491090367,
    344.23445849344307,
    321.3232735991368,
    386.4940871410583,
    376.42270922565643,
    300.5577841326441,
    390.1443379507978,
    341.39646782938684,
    382.73167393787696,
    368.15886233971815,
    395.99302897574154,
    381.13502174875,
    377.75984094048727,
    384.28523884144755,
    327.37809121631165,
    311.68663856684157,
    383.862058046354,
    300.6669708001032,
    371.7491199289741,
    393.7288659212555,
    375.2172653933477,
    322.5501480340269,
    385.04172190900005,
    366.2017113366428,
    340.20910901536024,
    326.9705852752014,
    355.87784326191445,
    370.3487223726981,
    385.56135893899943,
    367.63257222533605,
    319.0788654416142,
    363.3367560757454,
    326.1958050407169,
    358.7598773660632,
    366.5057561811811,
    335.6399088905399,
    318.8581217675496,
    330.1886403212113,
    372.60052814734865,
    394.79953171949256,
    310.3324427908511,
    327.5731587589721,
    324.0932040973229,
    384.1575967389848,
    382.3373041127981,
    365.025551630717,
    393.6173694829447,
    355.5216500274348,
    316.70682205925584,
    350.5020256958136,
    370.51652517564617,
    369.3970983372566,
    365.3739244225594,
    344.3448974301782,
    398.70531481046896,
    323.99918874615634,
    314.29215428794646,
    325.89679909850906,
    363.5663611461014,
    344.0676891552421,
    304.91551535623563,
    377.96791663740424,
    374.575540437151,
    354.22804079763694,
    346.7832567948218,
    349.23616796022554,
    363.43496608581455,
    301.2223408822193,
    386.98761299439764,
    378.09129227017723,
    356.65672105893555,
    332.9971778530732,
    381.28262006656064,
    346.59086611677435,
    362.7373375415147,
    327.0576106813524,
    368.87926120880934,
    349.0150859967897,
    316.84741165075826,
    339.4465631920367,
    301.3747885241463,
    352.155186361937,
    368.97616139052457,
    361.7051669551622,
    318.3039312222704,
    336.93424383934365,
    345.75920690899994,
    344.156798721507,
    314.42837710456826,
    391.611887079728,
    378.6330666950369,
    348.33154170416935,
    318.5559328156087,
    347.19942798767136,
    352.7089478532087,
    372.378999629688,
    379.74401119875535,
    336.755594110428,
    388.40280816553496,
    379.5429692959526,
    340.40385383772116,
    380.60968458291245,
    317.83358447078723,
    364.16445548853545,
    361.6532377214963,
    333.68802254459195,
    340.6187493676608,
    379.90934455657134,
    304.69755609715907,
    331.1076952096199,
    378.0470090073717,
    356.7023791799244,
    380.82101416270007,
    319.2947753816978,
    336.5554851111299,
    332.5693012639209,
    335.23461635285497,
    352.5578225288588,
    358.84563369060805,
    320.15990822938903,
    300.54351011223036,
    371.82355764807005,
    334.0034813838853,
    361.12868507740956,
    344.5427591146428,
    322.4376718360537,
    376.4842543818531
  ],
  0.1: [
    391.37125425645314,
    397.8352255931921,
    387.8581174047051,
    312.96223953618414,
    312.08336403034787,
    387.8417965184162,
    390.64060423720053,
    375.52597897994303,
    313.7101596741905,
    388.0322394467148,
    387.6770789428441,
    387.4228110054148,
    393.3394853801597,
    389.0203242438065,
    313.559348806884,
    312.0342107971708,
    387.44111396605877,
    393.9457382274728,
    315.7264733648992,
    313.5665744532051,
    387.485136740013,
    370.7454512433611,
    390.0152374608809,
    306.75150794349685,
    321.0784641869378,
    346.20176964707684,
    389.0666061958535,
    386.8738012004021,
    387.47172322636203,
    312.5728040910049,
    309.56857624578555,
    357.08730732706954,
    307.63040447250216,
    311.9964421332664,
    302.2576007315631,
    307.666691322593,
    387.7722064742966,
    389.80812423875113,
    354.23338447473725,
    389.00823129755724,
    393.7351652338649,
    312.53499568346865,
    387.2351773133062,
    312.06880801617405,
    312.5694969639586,
    389.7829521713036,
    389.78876003421885,
    376.62192706805365,
    312.0042545890771,
    312.3840729362261,
    390.4501001006277,
    387.53195624294347,
    311.8913689309141,
    310.24699837069176,
    388.0278626465265,
    389.4123026331583,
    387.4575033019643,
    389.8181045322026,
    344.64583098357775,
    310.0648262648484,
    387.9521403292648,
    389.3666390448242,
    387.4979345734731,
    393.7600105314879,
    310.0225960771788,
    354.39695336574385,
    389.7355623420935,
    391.85779402419513,
    389.9956879389793,
    312.7940409641805,
    309.6600728703643,
    348.9325511307185,
    312.86456287610525,
    307.7471445505836,
    311.96469066072177,
    312.323689588882,
    312.5118960245313,
    324.54254712749656,
    387.7567513855507,
    387.4891323559001,
    387.5238831865988,
    390.61192626125995,
    387.48812500422684,
    372.8425153058405,
    309.2715028937086,
    312.1437617809926,
    388.84939411182023,
    391.85778984948877,
    393.5748488601659,
    347.0195550021009,
    351.11025858263974,
    387.21677105675815,
    310.06560926681345,
    308.0096341803275,
    396.51449418425466,
    318.5891862863364,
    390.69049490303644,
    390.5766890853024,
    387.1263052993577,
    387.55664333209984,
    392.08999605578384,
    313.4805477507702,
    392.38572376709806,
    390.60669234430475,
    319.36394787685254,
    314.2009748542812,
    386.4631100929086,
    312.32845513012654,
    392.52985201919637,
    353.5829364447616,
    312.4451459224632,
    347.37261192663345,
    312.80270201059705,
    312.0364586012239,
    356.85550105187826,
    389.72705458220605,
    313.61674109667644,
    384.8407189650988,
    389.9376510249829,
    304.35999484460154,
    332.4577911354953,
    389.9445472397579,
    376.5748272743943,
    355.05156036691255,
    331.0026397242206,
    388.2757994883096,
    312.0727769682439,
    389.8980083073568,
    312.4496425200501,
    392.36999543250766,
    386.45319604612547,
    389.4111983094828,
    306.91890869394365,
    312.945965918616,
    354.37168304690476,
    307.5422586254141,
    373.75831622574725,
    312.81355647957247,
    306.8122608539679,
    312.44452444177534,
    386.1905676542407,
    310.01915310086935,
    306.75322165955225,
    369.47793858422773,
    387.68266891989447,
    354.3367616742772,
    313.085820613207,
    308.67563606950546,
    387.5653761459977,
    391.65336954866575,
    387.1022909238462,
    347.37439363122263,
    309.307072902099,
    308.6760775197455,
    310.0355715431728,
    347.744495071403,
    310.0242502072141,
    309.65853634045305,
    387.5257358288997,
    309.9700731845933,
    311.8440834915079,
    313.05653063924984,
    310.2538278857546,
    312.5299084582343,
    391.85198457166086,
    389.0415960535604,
    389.99916451218195,
    387.6831728342984,
    343.14595480501737,
    382.4090515737775,
    387.5720743212633,
    398.42922264009513,
    387.253804126692,
    390.7222968687224,
    342.91271580935654,
    307.8213645054704,
    393.21874163360764,
    310.93676206467825,
    310.2663422357913,
    378.05209646010155,
    307.9119286548143,
    312.1691375247087,
    387.8601730775307,
    312.23241269440604,
    391.94456127633003,
    313.9850289178357,
    312.5504137924967,
    307.63526897524883,
    310.42898316573735,
    389.6376740683901,
    386.54308086548093,
    387.017628210508,
    312.35012281282667,
    386.26829791940804,
    361.7640979517384,
    324.65250263622795,
    312.5487938486784,
    304.0213510340997,
    310.2581452892306,
    308.1502129095158
  ],
  0: [
    363.7703413236796,
    389.6788112663877,
    312.809824063973,
    388.14176480030324,
    398.19931634562033,
    392.7484074802357,
    385.82549391893303,
    385.7535827205117,
    393.3884215134646,
    347.7713762554903,
    385.62776229794906,
    386.3553484891449,
    315.69307488231505,
    367.05248873142443,
    399.3905227271402,
    373.6810103174772,
    343.8847516398201,
    344.40745421123273,
    309.22295423769026,
    307.3850717186574,
    301.99796483746064,
    382.4093053298354,
    393.02563295376143,
    330.0405498712078,
    390.39361860426936,
    336.8037651835407,
    351.97253019153163,
    309.8666394291248,
    324.94610207099083,
    341.9986022457507,
    328.7811923869472,
    393.2386334933069,
    314.9232035421496,
    344.69642714605135,
    310.27056015669507,
    315.65067642395087,
    357.8131824278941,
    309.34872067004346,
    328.8340238303108,
    312.47492915325125,
    306.7636180198973,
    380.7892625636703,
    343.6644607107317,
    376.9656436993586,
    314.13590232033056,
    388.0178846896293,
    372.29582037660407,
    315.25706194627907,
    375.1574881296548,
    359.7265510618375,
    339.69571649503706,
    359.41476828506796,
    307.0192911622018,
    304.3694225366043,
    344.6783578513791,
    389.3425816761673,
    392.45493257966956,
    388.12496799924554,
    363.2537400716167,
    363.9503975676236,
    389.4648182049473,
    392.79649950596104,
    391.1339321485838,
    337.20349981124525,
    347.3671897446176,
    394.97091745342635,
    329.151371574719,
    392.41734466518216,
    344.3292165493242,
    346.9862033593257,
    391.5787079039023,
    386.97771591230315,
    345.0272570089066,
    322.0807676336278,
    344.32110216485484,
    307.14841818648284,
    311.06780842649823,
    307.8056184673882,
    349.87985429056624,
    323.2304230951571,
    395.4300293867973,
    367.7087418739825,
    393.25061306134603,
    366.08022446100256,
    328.7679220273582,
    327.75666494750527,
    392.05825823025015,
    351.29211037485885,
    386.2173264496302,
    307.07994926254094,
    390.4568426072377,
    350.1520076776324,
    373.49368965673045,
    388.91793611457575,
    378.91173536267763,
    389.82590907571335,
    318.7107135252894,
    357.41433084307835,
    327.30719834064894,
    346.643796617219,
    314.36904748564604,
    305.79220351723615,
    310.11271627275653,
    377.91135499284394,
    377.51048515869263,
    387.3175220733888,
    322.0876651992186,
    383.40072380986453,
    370.5359355471363,
    346.74175404781175,
    382.70665561234773,
    310.8633348563421,
    344.69147521213677,
    364.4142464066591,
    350.47110861084707,
    396.228304287142,
    311.26467323457655,
    357.47579970866855,
    315.04473829125476,
    302.33003746483547,
    386.4224821443235,
    369.9613706037033,
    307.1638766890688,
    391.0251928469233,
    356.6431805544526,
    316.7940597635016,
    323.9077614376299,
    307.91319729956103,
    342.101416304017,
    388.6146296371093,
    319.9676283644452,
    388.31744152716374,
    386.8993078147381,
    308.3299910529606,
    384.6982887598473,
    321.0272267635076,
    325.9582696050049,
    306.85973291997107,
    352.65158475320925,
    312.015340256994,
    312.8720305590261,
    307.6398012887943,
    396.9626577082469,
    350.0222027795216,
    338.48437968846514,
    393.0817225879514,
    391.91144999468406,
    353.0131097577446,
    392.67911547287605,
    313.51167616996366,
    391.1222804987936,
    352.30082965311857,
    337.49524829484506,
    305.68001721882666,
    342.857741225774,
    308.09096712038036,
    320.29085714067634,
    361.8002994449376,
    389.6487602772211,
    392.90115640658456,
    392.3288754456644,
    328.790541627356,
    332.938783622174,
    393.07229677998845,
    360.7344071407353,
    328.76195337269684,
    353.0099869070846,
    342.4623661484772,
    310.28431287245303,
    390.68283974277284,
    311.9726628048522,
    397.0097164783988,
    322.0761253699816,
    352.9791803498583,
    329.4490738598772,
    397.01114261721807,
    388.1148914495643,
    310.63896154009745,
    355.92091742073256,
    323.7999929718163,
    392.10538152208517,
    303.0390495981692,
    310.0866200666571,
    393.0519831080596,
    354.87608384501317,
    386.42446038075684,
    309.34726449855503,
    304.9628932364638,
    352.2554259777343,
    350.5575595556305,
    396.93997299960523,
    307.5234124170308,
    307.50093057202236,
    311.4482462490397,
    318.93402619729596,
    361.74866832757493,
    353.2374664815275,
    362.76006672136435,
    339.1681787830001,
    311.896781240796
  ]
}

export function getInputValueD(multiplier) {
  const arrayTemp = multiplierVariablesD[multiplier]
  const randomIndex = Math.floor(Math.random() * arrayTemp.length);
  return arrayTemp[randomIndex]
}

export function getInputValueM(multiplier) {
  const arrayTemp = multiplierVariablesM[multiplier]
  const randomIndex = Math.floor(Math.random() * arrayTemp.length);
  return arrayTemp[randomIndex]
}
