import { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../../../../../components/Button";
import { useAuthStore } from "../../../../../../../store/auth";
import { CircleDashed } from "phosphor-react";

export function BetActions({ onRunBet, onChangeLines, inGameBallsCount }) {
  const isLoading = useAuthStore((state) => state.isBetLoading);
  const currentBalance = Math.floor(useAuthStore((state) => state.activeBalance));
  const toggleBetLoading = useAuthStore((state) => state.toggleBetLoading);
  const isAuth = useAuthStore((state) => state.isAuth);
  const [betValue, setBetValue] = useState(0);
  const [ballAmount, setBallAmount] = useState(1);
  const maxLinesQnt = 16;
  const linesOptions = [];
  for (let i = 8; i <= maxLinesQnt; i++) {
    linesOptions.push(i);
  }

  function handleChangeBetValue(e) {
    if (!isAuth || isLoading) return;
    e.preventDefault();
    let value = e.target.value;

    // Remove leading zeros and parse as integer
    value = value.replace(/^0+/, "") || "0";
    const newBetValue = Math.floor(value);

    // Check if new value exceeds the current balance
    const finalBetValue = newBetValue > currentBalance ? currentBalance : newBetValue;
    setBetValue(finalBetValue);
  }

  function handleChangeBallAmount(e) {
    if (!isAuth || isLoading) return;
    e.preventDefault();
    const value = Math.round(+e.target.value);
    const newBallAmount = value > 15 ? 15 : value;
    setBallAmount(newBallAmount);
  }

  // function handleChangeLines(e) {
  //   return
  //   if (!isAuth || isLoading) return;
  //   onChangeLines(Number(e.target.value));
  // }

  function handleHalfBet() {
    if (!isAuth || isLoading) return;
    const value = Math.floor(betValue / 2);
    const newBetvalue = value <= 0 ? 0 : value;
    setBetValue(newBetvalue);
  }

  function handleDoubleBet() {
    if (!isAuth || isLoading) return;
    const value = betValue * 2;
    let betValueTotal = value * ballAmount;
    if (value >= currentBalance) {
      setBetValue(Math.floor(currentBalance / ballAmount));
      return;
    }
    const newBetvalue = value <= 0 ? 0 : Math.floor(value);
    setBetValue(newBetvalue);
  }

  function handleMaxBet() {
    if (!isAuth || isLoading) return;
    setBetValue(currentBalance);
  }

  async function handleRunBet() {
    if (!isAuth || isLoading) return;
    let betValueTotal = betValue * ballAmount;
    if (betValueTotal > currentBalance) {
      toast.error(
        "You dont have enough DONK in your account for that bet. If you think this is incorrect, try refreshing."
      );
      setBetValue(Math.floor(currentBalance / ballAmount));
      return;
    }
    if (betValue < 10000) {
      toast.error("You must bet a minimum of 10000 $DONK per ball.");
      setBetValue(10000);
      return;
    }
    toggleBetLoading(true);
    onRunBet(betValue, ballAmount);
  }

  // TODO: work with the input values so that only whole numbers can be added
  return (
    <div className="relative h-auto w-full flex-1 ">
      {/* <span className="absolute left-4 top-0 mx-auto text-xs font-bold text-text md:text-base">
        Balls in play: {inGameBallsCount}
      </span> */}

      <div className="flex h-full flex-col gap-4  text-text lg:text-black md:justify-between lg:bg-purple-100 lg:p-4 rounded">
        <div className="flex w-full gap-2">
          <div className="w-2/3 text-sm font-bold md:text-base">
            <div className="grid grid-cols-[0.2fr,1fr] items-center gap-2">
              <label htmlFor="bet-amount" className="min-w-max leading-none font-normal">
                Bet Amount
              </label>
              <input
                id="bet-amount"
                type="number"
                min={0}
                max={currentBalance}
                onChange={handleChangeBetValue}
                value={betValue.toString()}
                className="w-full bg-white/60 lg:bg-purple-200 border border-black rounded p-1 px-3 text-right text-lg outline-none"
              />
              <div className="flex gap-1 items-center justify-center col-start-2">
                <Button onClick={handleHalfBet}>half</Button>
                <Button onClick={handleDoubleBet}>2x</Button>
                <Button onClick={handleMaxBet}>max</Button>
              </div>
              <label htmlFor="balls-amount" className="leading-none font-normal">
                Number of Balls
              </label>

              <input
                id="balls-amount"
                className="w-full bg-white/60 lg:bg-purple-200 border border-black rounded p-1 px-3 text-right text-lg outline-none"
                type="number"
                min={0}
                max={15}
                onChange={handleChangeBallAmount}
                value={ballAmount}
              />
            </div>
          </div>
          <div className="w-1/3 flex flex-col items-center gap-0.5 ">
            <span className="text-lg">Total Wager </span>
            <span className="text-lg">{Math.floor(betValue * ballAmount)}</span>
            <button
              onClick={handleRunBet}
              disabled={isLoading || !isAuth}
              className="link mt-auto text-purple-700 flex items-center justify-center">
              bet
              {
                isLoading ?
                <CircleDashed className="ml-2 animate-spin" size="20" weight="bold" />
                :
                <></>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
